import { useMemo } from 'react';

import { Box, Flex, Icon, Progress, Text } from '@chakra-ui/react';
import { FaCheckCircle, FaClock, FaReply, FaShare } from 'react-icons/fa';

import { useSetorAtual } from '../../context/ProviderSetorAtual';

import { useGetTotaisSolicitacoesPorGrupoQuery } from '../../services/sidApi';
import { pollingInterval } from '../../utils';

import ResumoAnoLoading from './ResumoAnoLoading';

const SITUACOES_EVENTO_MAP = {
  Recebida: {
    text: 'Recebidas',
    icon: FaReply,
    bgColor: 'blue.400',
    bgHover: 'blue.500',
    textColor: 'white',
  },

  Enviada: {
    text: 'Enviadas',
    icon: FaShare,
    bgColor: 'red.400',
    bgHover: 'red.500',
    textColor: 'white',
  },

  Devolvida: {
    text: 'Em andamento',
    icon: FaClock,
    bgColor: 'yellow.300',
    bgHover: 'yellow.400',
    textColor: 'black',
  },

  Baixada: {
    text: 'Baixadas',
    icon: FaCheckCircle,
    bgColor: 'green.500',
    bgHover: 'green.600',
    textColor: 'white',
  },
};

export default function ResumoAno(props) {
  const { setorAtual } = useSetorAtual();

  const currentDate = useMemo(() => {
    const localDate = new Date();

    return {
      date: localDate,
      toString() {
        return `(${localDate.getFullYear()})`;
      },
    };
  }, []);

  const {
    data: totaisSolicitacoesPorGrupo = [],
    isFetching,
    isLoading,
  } = useGetTotaisSolicitacoesPorGrupoQuery(
    {
      ano: new Date().getFullYear(),
      setor_origem: setorAtual,
    },
    {
      pollingInterval: pollingInterval(),
      skip: setorAtual === null || setorAtual === '',
    },
  );

  let totaisSolicitacoesPorGrupoTransformed = [];

  if (totaisSolicitacoesPorGrupo.length) {
    totaisSolicitacoesPorGrupoTransformed = Object.keys(
      SITUACOES_EVENTO_MAP,
    ).map(situacaoNome => {
      const totalSolicitacao = totaisSolicitacoesPorGrupo.find(
        el => el.grupo_nome === situacaoNome,
      );

      if (totalSolicitacao) {
        return {
          nome: situacaoNome,
          total: totalSolicitacao.total,
        };
      }
      return {
        nome: situacaoNome,
        total: 0,
      };
    });
  } else {
    totaisSolicitacoesPorGrupoTransformed = Object.keys(
      SITUACOES_EVENTO_MAP,
    ).map(nome => ({
      nome,
      total: 0,
    }));
  }

  if (isLoading) {
    return <ResumoAnoLoading />;
  }

  return (
    <Box {...props}>
      <Text fontWeight="bold" fontSize="lg" mb="3">
        Resumo do Ano {currentDate.toString()}
      </Text>

      <Progress size="xs" isIndeterminate={isFetching} />

      <Flex
        border="1px solid"
        borderColor="gray.300"
        p="2"
        sx={{ gap: '0.5rem' }}
      >
        {totaisSolicitacoesPorGrupoTransformed.map(solicitacaoTotal => (
          <Flex
            key={solicitacaoTotal?.nome}
            direction="column"
            bgColor={SITUACOES_EVENTO_MAP[solicitacaoTotal?.nome]?.bgColor}
            _hover={{
              bgColor: SITUACOES_EVENTO_MAP[solicitacaoTotal?.nome]?.bgHover,
            }}
            color={SITUACOES_EVENTO_MAP[solicitacaoTotal?.nome]?.textColor}
            p="5"
            flex="1"
          >
            <Flex alignItems="center" mb="2">
              <Icon
                as={SITUACOES_EVENTO_MAP[solicitacaoTotal?.nome]?.icon}
                fontSize="3xl"
                mr="2"
              />
              <Text>{solicitacaoTotal?.total}</Text>
            </Flex>

            <Text>{SITUACOES_EVENTO_MAP[solicitacaoTotal?.nome]?.text}</Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
