export default function InlineDateTime({ date, children }) {
  const dateObj = new Date(date);

  const dateFormatted = new Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'short',
  }).format(dateObj);
  const timeFormatted = new Intl.DateTimeFormat('pt-BR', {
    timeStyle: 'short',
  }).format(dateObj);

  return children({ dateFormatted, timeFormatted });
}
