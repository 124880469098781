import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiToken = () => localStorage.getItem('@sid.apiToken');

export const sidApi = createApi({
  reducerPath: 'sidApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}/`,

    prepareHeaders: headers => {
      headers.set('Accept', 'application/json');
      headers.set('Authorization', `Token ${apiToken()}`);

      return headers;
    },
  }),

  tagTypes: ['Notificacao'],

  endpoints: builder => ({
    getDefensorias: builder.query({
      query: 'defensorias',
    }),

    getSolicitacoesPorSituacao: builder.query({
      query: params => ({ url: 'eventos/', params }),
    }),

    // TODO: Remover método
    getEventosTotaisPorSituacao: builder.query({
      query: params => ({ url: 'eventos/totais-por-situacao/', params }),
      transformResponse: response =>
        response.results.filter(evento => evento.situacao !== 0),
    }),

    getTotaisSolicitacoesPorGrupo: builder.query({
      query: params => ({
        url: 'solicitacoes/totais-envidas-recebidas/',
        params,
      }),
      transformResponse: response => response.results,
    }),

    getEventosTotaisPorData: builder.query({
      query: params => ({ url: 'eventos/totais-por-data/', params }),
      transformResponse: response => response.results,
    }),

    getNotificacoes: builder.query({
      query: setorId => ({
        url: 'notificacoes/',
        params: {
          setor: setorId,
          ordering: 'cadastrado_em',
          lida: false,
          limit: 500,
        },
      }),

      transformResponse: response => response.results,

      providesTags: ['Notificacao'],
    }),
  }),
});

export const {
  useGetDefensoriasQuery,
  useGetSolicitacoesPorSituacaoQuery,
  useGetTotaisSolicitacoesPorGrupoQuery,
  useGetEventosTotaisPorDataQuery,
  useGetNotificacoesQuery,
} = sidApi;
