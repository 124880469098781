import {
  Box,
  Container,
  Flex,
  HStack,
  SimpleGrid,
  Skeleton,
  VStack,
} from '@chakra-ui/react';

export default function SolicitacaoLoading() {
  return (
    <Container maxW="container.xl">
      <Flex mt="4" mb="4">
        <Skeleton w="96" h="10" />

        <HStack ml="auto">
          <Skeleton w="32" h="10" />
          <Skeleton w="32" h="10" />
          <Skeleton w="32" h="10" />
        </HStack>
      </Flex>

      <SimpleGrid templateColumns="20rem 1fr" gap="10">
        <VStack>
          <Skeleton w="full" h="90" />
          <Skeleton w="full" h="90" />
          <Skeleton w="full" h="full" />
        </VStack>

        <Box>
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" mb="4" />
          <Skeleton w="full" h="10" />
        </Box>
      </SimpleGrid>
    </Container>
  );
}
