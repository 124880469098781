import { Box, Button, Container, Heading, Icon } from '@chakra-ui/react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

export default function SolicitacaoError() {
  return (
    <Container maxW="container.xl">
      <Box mt="4" textAlign="center">
        <Heading as="h2" fontSize="2xl" mb="3">
          Solicitação não localizada!
        </Heading>

        <Button
          as={RouterLink}
          to="/acompanhamento"
          colorScheme="green"
          leftIcon={<Icon as={FaArrowLeft} />}
        >
          Voltar
        </Button>
      </Box>
    </Container>
  );
}
