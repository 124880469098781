import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { getSituacoesSolicitacao } from '../services/api';

export default function useFetchSituacoesSolicitacao() {
  const [situacoesSolicitacao, setSituacoesSolicitacao] = useState([]);

  useEffect(() => {
    const fetchSituacoesSolicitacao = async () => {
      try {
        const data = await getSituacoesSolicitacao();
        setSituacoesSolicitacao(data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchSituacoesSolicitacao();
  }, []);

  return situacoesSolicitacao;
}
