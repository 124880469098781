import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from 'react-router-dom';

import { useAuth } from './context/ProviderAuth';

import Acompanhamento from './features/acompanhamento/Acompanhamento';
import NovoEvento from './features/evento/NovoEvento';
import AlterarSenha from './features/login/AlterarSenha';
import Login from './features/login/Login';
import RecuperarSenha from './features/login/RecuperarSenha';
import RecuperarSenhaSolicitacao from './features/login/RecuperarSenhaSolicitacao';
import NovaSolicitacao from './features/nova_solicitacao/NovaSolicitacao';
import Solicitacao from './features/solicitacoes/Solicitacao';
import SolicitacoesBusca from './features/solicitacoes/SolicitacoesBusca';

import AuthenticatedLayout from './layouts/AuthenticatedLayout';

function RecuperarSenhaRoute() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  return token !== null ? <RecuperarSenha /> : <RecuperarSenhaSolicitacao />;
}

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/recuperar-senha" element={<RecuperarSenhaRoute />} />

        <Route
          path="/alterar-senha"
          element={
            <PrivateRoute>
              <AuthenticatedLayout>
                <AlterarSenha />
              </AuthenticatedLayout>
            </PrivateRoute>
          }
        />

        <Route
          path="/acompanhamento"
          element={
            <PrivateRoute>
              <AuthenticatedLayout>
                <Acompanhamento />
              </AuthenticatedLayout>
            </PrivateRoute>
          }
        />

        <Route
          path="/solicitacoes/busca"
          element={
            <PrivateRoute>
              <AuthenticatedLayout>
                <SolicitacoesBusca />
              </AuthenticatedLayout>
            </PrivateRoute>
          }
        />

        <Route
          path="/solicitacoes/:id"
          element={
            <PrivateRoute>
              <AuthenticatedLayout>
                <Solicitacao />
              </AuthenticatedLayout>
            </PrivateRoute>
          }
        />

        <Route
          path="/nova-solicitacao"
          element={
            <PrivateRoute>
              <AuthenticatedLayout>
                <NovaSolicitacao />
              </AuthenticatedLayout>
            </PrivateRoute>
          }
        />

        <Route
          path="/solicitacoes/:id/novo-evento"
          element={
            <PrivateRoute>
              <AuthenticatedLayout>
                <NovoEvento />
              </AuthenticatedLayout>
            </PrivateRoute>
          }
        />

        <Route path="/" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}

function PrivateRoute({ children }) {
  const auth = useAuth();
  const location = useLocation();

  if (auth.user == null) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
