import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  error: null,
};

export const notificacoesSlice = createSlice({
  name: 'notificacoes',

  initialState,

  reducers: {
    adicionarNotificacoes(state, action) {
      state.data = action.payload;
    },

    removerNotificacao(state, action) {
      const id = action.payload;
      const index = state.data.findIndex(el => el.id === id);
      state.data.splice(index, 1);
    },
  },
});

export default notificacoesSlice.reducer;

export const { adicionarNotificacoes, removerNotificacao } =
  notificacoesSlice.actions;

export const selectNotificacoes = state => state.notificacoes.data;
