import * as Sentry from '@sentry/react';

export const TokenService = {
  getUserFromSimulate() {
    const simulatedToken = localStorage.getItem('@sid.simulate');

    if (simulatedToken == null || simulatedToken === '') {
      return null;
    }

    const data = JSON.parse(simulatedToken);

    if (data.apiToken != null && data.apiToken !== '' && data.user != null) {
      localStorage.setItem('@sid.apiToken', data.apiToken);
      localStorage.setItem('@sid.setorAtual', data.setorAtual);
      localStorage.setItem('@sid.user', JSON.stringify(data.user));

      return data.user;
    }

    return null;
  },

  getUserFromLocalStorage() {
    const apiToken = localStorage.getItem('@sid.apiToken');

    if (apiToken) {
      const user = JSON.parse(localStorage.getItem('@sid.user'));

      Sentry.setUser({
        id: user.id,
        email: user.email,
        username: user.username,
      });

      return user;
    }

    return null;
  },

  getUser() {
    return this.getUserFromSimulate() || this.getUserFromLocalStorage();
  },
};

export default {};
