import { Button, chakra, HStack } from '@chakra-ui/react';
import { useState } from 'react';

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';

const getRange = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

export default function Pagination({
  totalRecords = 0,
  pageLimit = 5,
  pageNeighbours = 0,
  onPageChanged,
}) {
  pageNeighbours = Math.max(0, Math.min(pageNeighbours, 2));

  const totalPages = Math.ceil(totalRecords / pageLimit);

  const [currentPage, setCurrentPage] = useState(1);

  const fetchPageNumbers = () => {
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      let pages = getRange(startPage, endPage);

      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = getRange(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        case !hasLeftSpill && hasRightSpill: {
          const extraPages = getRange(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }

    return getRange(1, totalPages);
  };

  const goToPage = page => {
    const localCurrentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage: localCurrentPage,
      totalPages,
      pageLimit,
      totalRecords,
    };

    setCurrentPage(localCurrentPage);
    onPageChanged(paginationData);
  };

  const pages = fetchPageNumbers();

  const handleMoveLeft = () => {
    goToPage(currentPage - pageNeighbours * 2 - 1);
  };

  const handleMoveRight = () => {
    goToPage(currentPage + pageNeighbours * 2 + 1);
  };

  const handleClick = page => {
    goToPage(page);
  };

  return (
    <chakra.nav aria-label="pagination">
      <HStack>
        {pages.map(page => {
          if (page === LEFT_PAGE) {
            return (
              <Button key={page} aria-label="Anterior" onClick={handleMoveLeft}>
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Anterior</span>
              </Button>
            );
          }

          if (page === RIGHT_PAGE) {
            return (
              <Button key={page} aria-label="Próximo" onClick={handleMoveRight}>
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Próximo</span>
              </Button>
            );
          }

          return (
            <Button
              key={page}
              colorScheme={currentPage === page ? 'blue' : null}
              onClick={() => handleClick(page)}
            >
              {page}
            </Button>
          );
        })}
      </HStack>
    </chakra.nav>
  );
}
