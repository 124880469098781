import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Container, Flex, Icon } from '@chakra-ui/react';

import { FaPlusCircle } from 'react-icons/fa';

import AtividadesMes from './AtividadesMes';
import AtividadesRecentes from './AtividadesRecentes';
import PrateleiraSolicitacoes from './PrateleiraSolicitacoes';
import PrateleiraSolicitacoesEnviadas from './PrateleiraSolicitacoesEnviadas';
import ResumoAno from './ResumoAno';

export default function Acompanhamento() {
  return (
    <Container maxW="1400px" p="2">
      <Flex justifyContent="flex-end">
        <Button
          colorScheme="green"
          leftIcon={<Icon as={FaPlusCircle} />}
          as={RouterLink}
          to="/nova-solicitacao"
        >
          Nova Solicitação
        </Button>
      </Flex>

      <Flex sx={{ gap: '1rem' }}>
        <Box flex="1">
          <AtividadesMes mb="3" />
          <PrateleiraSolicitacoes />
          <PrateleiraSolicitacoesEnviadas />
        </Box>

        <Box flex="1">
          <ResumoAno mb="3" />
          <AtividadesRecentes />
        </Box>
      </Flex>
    </Container>
  );
}
