import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import { sidApi } from '../services/sidApi';

import defensoriasReducer from '../features/defensorias/defensoriasSlice';
import situacoesEventoReducer from '../features/evento/situacoesEventoSlice';
import localidadesReducer from '../features/localidades/localidadesSlice';
import notificacoesReducer from '../features/notificacoes/notificacoesSlice';
import tiposEmailReducer from '../features/nova_solicitacao/tiposEmailSlice';
import tiposSolicitacaoReducer from '../features/nova_solicitacao/tiposSolicitacaoSlice';
import tiposTelefoneReducer from '../features/nova_solicitacao/tiposTelefoneSlice';
import setoresReducer from '../features/setores/setoresSlice';

const store = configureStore({
  reducer: {
    tiposSolicitacao: tiposSolicitacaoReducer,
    setores: setoresReducer,
    defensorias: defensoriasReducer,
    tiposTelefone: tiposTelefoneReducer,
    tiposEmail: tiposEmailReducer,
    situacoesEvento: situacoesEventoReducer,
    localidades: localidadesReducer,
    notificacoes: notificacoesReducer,

    [sidApi.reducerPath]: sidApi.reducer,
  },

  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(sidApi.middleware),
});

setupListeners(store.dispatch);

export default store;
