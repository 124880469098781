import { useContext, useState, createContext } from 'react';

const setorAtualContext = createContext();

export function ProviderSetorAtual({ children }) {
  const setorAtual = useProviderSetorAtual();

  return (
    <setorAtualContext.Provider value={setorAtual}>
      {children}
    </setorAtualContext.Provider>
  );
}

function useProviderSetorAtual() {
  const [setorAtual, setSetorAtual] = useState(
    localStorage.getItem('@sid.setorAtual'),
  );

  const changeSetorAtual = setorId => {
    setSetorAtual(setorId);
    localStorage.setItem('@sid.setorAtual', setorId);
  };

  return {
    setorAtual,
    changeSetorAtual,
  };
}

export function useSetorAtual() {
  return useContext(setorAtualContext);
}
