import { extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    500: '#4faf9f',
    900: '#1a365d',
    800: '#153e75',
    700: '#2a69ac',
  },
};

const styles = {
  global: {
    '.sr-only': {
      position: 'absolute',
      width: '1px',
      height: '1px',
      padding: '0',
      margin: '-1px',
      overflow: 'hidden',
      clip: 'rect(0, 0, 0, 0)',
      whiteSpace: 'nowrap',
      borderWidth: '0',
    },
  },
};

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({ colors, config, styles });

export default theme;
