import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getTiposSolicitacao } from '../../services/api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchTiposSolicitacao = createAsyncThunk(
  'tiposSolicitacao/fetchTiposSolicitacao',
  async () => {
    return getTiposSolicitacao();
  },
);

export const tiposSolicitacaoSlice = createSlice({
  name: 'tiposSolicitacao',

  initialState,

  extraReducers: {
    [fetchTiposSolicitacao.pending]: state => {
      state.status = 'loading';
    },

    [fetchTiposSolicitacao.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },

    [fetchTiposSolicitacao.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default tiposSolicitacaoSlice.reducer;

export const selectAllTiposSolicitacao = state => state.tiposSolicitacao.data;
