import { useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
} from '@chakra-ui/react';

import * as Sentry from '@sentry/react';
import { resetPasswordRequest } from '../../services/api';

import TestEnvironment from '../../components/TestEnvironment';

import UnauthenticatedNavbar from './UnauthenticatedNavbar';

export default function RecuperarSenhaSolicitacao() {
  const [email, setEmail] = useState('');

  const handleChangeEmail = event => setEmail(event.target.value);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      setIsLoading(true);

      const data = await resetPasswordRequest(email);

      setError(null);
      setSuccess(data);
    } catch (ex) {
      Sentry.captureException(ex);

      const errorMessage = ex.response?.data?.email?.[0] ?? ex.message;
      setSuccess(false);
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <UnauthenticatedNavbar />
      <TestEnvironment />

      <Container maxW="container.sm" mx="auto" p="6" h="full">
        <Box
          mx="auto"
          shadow="md"
          borderTop="1px solid"
          borderColor="gray.100"
          p="6"
        >
          <form onSubmit={handleSubmit}>
            <Heading as="h2" fontSize="1.75rem" textAlign="center" mb="6">
              Recuperar Senha
            </Heading>

            <FormControl mb="3" isRequired isInvalid={error != null}>
              <Input
                placeholder="Digite seu email"
                value={email}
                onChange={event => handleChangeEmail(event)}
              />

              <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>

            <Button type="submit" colorScheme="green" isLoading={isLoading}>
              Enviar
            </Button>
          </form>

          {success && (
            <Box
              borderLeft="4px solid"
              borderLeftColor="green.500"
              borderRadius="md"
              boxShadow="md"
              borderTop="1px solid"
              borderTopColor="gray.100"
              px="6"
              py="4"
              mt="5"
            >
              <Flex mb="3" alignItems="center">
                <Icon
                  as={FaCheckCircle}
                  color="green"
                  fontSize="1.75rem"
                  me="2"
                />
                <chakra.strong>Verifique seu email</chakra.strong>
              </Flex>
              Um link para restauração de senha foi enviado para o email
              fornecido. Abra o email para prosseguir com a restauração da
              senha.
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
}
