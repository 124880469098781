import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL,
});

const fetchResponse = async (endpoint, params) => {
  const apiToken = localStorage.getItem('@sid.apiToken');

  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${apiToken}`,
  };

  const defaultParams = { limit: 1000 };
  const queryParams = { ...defaultParams, ...params };

  const response = await api.get(`${endpoint}/`, {
    headers,
    params: queryParams,
  });

  if ('results' in response.data) {
    return response.data.results;
  }
  return response.data;
};

const fetchPaginatedResponse = async (endpoint, params) => {
  const apiToken = localStorage.getItem('@sid.apiToken');

  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${apiToken}`,
  };

  const response = await api.get(`${endpoint}/`, { headers, params });

  return response.data;
};

export const getTiposTelefone = async () => {
  return fetchResponse('/tipos-telefone');
};

export const getTiposEmail = async () => {
  return fetchResponse('/tipos-email');
};

export const getSituacoesEvento = async () => {
  return fetchResponse('/situacoes-evento');
};

export const getTiposEventos = async params => {
  return fetchResponse('/tipos-evento', params);
};

export const getTiposEventoPorSolicitacao = async (solicitacaoId, params) => {
  return fetchResponse(`/solicitacoes/${solicitacaoId}/tipos-evento`, params);
};

export const getEventos = async params => {
  return fetchResponse('/eventos', params);
};

export const getEventosTotaisPorData = async (ano, mes, setorCriacao) => {
  if (setorCriacao !== null && setorCriacao !== '') {
    const params = { ano, mes, setor_criacao: setorCriacao };
    return fetchResponse('/eventos/totais-por-data', params);
  }
  return [];
};

export const getResponderOutroEventoOpcoes = async (
  solicitacaoId,
  setorEncaminhado,
) => {
  const params = {
    solicitacao: solicitacaoId,
    setor_encaminhado: setorEncaminhado,
    situacao: 2,
  };

  return fetchResponse('/eventos', params);
};

export const getEventosTotaisPorSituacao = async (ano, setorId) => {
  if (setorId !== null && setorId !== '') {
    const params = { ano, setor_criacao: setorId };
    const results = await fetchResponse('/eventos/totais-por-situacao', params);
    return results.filter(el => el.situacao !== 0);
  }
  return [];
};

export const getDefensorias = async () => {
  return fetchResponse('/defensorias');
};

export const getLocalidades = async defensoriaId => {
  const params = { defensoria: defensoriaId };
  return fetchResponse('/localidades', params);
};

export const getTiposSolicitacao = async () => {
  return fetchResponse('/tipos-solicitacao');
};

export const getSolicitacoes = async params => {
  return fetchResponse('/solicitacoes', params);
};

export const getSolicitacaoPorId = async solicitacaoId => {
  return fetchResponse(`/solicitacoes/${solicitacaoId}`);
};

export const getSolicitacoesPaginada = async params => {
  return fetchPaginatedResponse('/solicitacoes', params);
};

export const getSituacoesSolicitacao = async () => {
  return fetchResponse('/situacoes-solicitacao');
};

export const getSetoresParaEncaminhamento = async (solicitacaoId, setorId) => {
  const params = { setor_criacao: setorId };
  return fetchResponse(
    `/solicitacoes/${solicitacaoId}/setores-para-encaminhamento`,
    params,
  );
};

export const getSetores = async params => {
  return fetchResponse('/setores', params);
};

export const getSetor = async (setorId, params) => {
  return fetchResponse(`/setores/${setorId}`, params);
};

export const getSetoresPorResponsavel = async responsavelId => {
  const params = { responsaveis: responsavelId };
  return getSetores(params);
};

export const salvarEvento = async payload => {
  const apiToken = localStorage.getItem('@sid.apiToken');

  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${apiToken}`,
  };

  const response = await api.post('/eventos/', payload, { headers });
  return response.data;
};

export const salvarSolicitacao = async payload => {
  const apiToken = localStorage.getItem('@sid.apiToken');

  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${apiToken}`,
  };

  return api.post('/solicitacoes/', payload, { headers });
};

export const uploadDocument = async formData => {
  const apiToken = localStorage.getItem('@sid.apiToken');

  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${apiToken}`,
  };

  const response = await api.post('/documentos/', formData, { headers });
  return response.data;
};

export const getTiposDocumento = async () => {
  return fetchResponse('/tipos-documento');
};

export const getToken = async (username, password) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  const payload = { username, password };

  const { data } = await api.post('/api-token-auth/', payload, { headers });
  return data;
};

export const getSetorAtual = async userId => {
  const params = { responsaveis: userId };

  const [setorAtual] = await getSetores(params);

  return setorAtual;
};

export const alterarSenha = async (
  senhaAtual,
  novaSenha,
  confirmacaoSenha,
  userId,
) => {
  const apiToken = localStorage.getItem('@sid.apiToken');

  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${apiToken}`,
  };

  const payload = {
    old_password: senhaAtual,
    password: novaSenha,
    password_confirmation: confirmacaoSenha,
  };

  const { data } = await api.put(`/change-password/${userId}/`, payload, {
    headers,
  });
  return data;
};

export const resetPasswordRequest = async email => {
  const headers = {
    Accept: 'application/json',
  };

  const payload = { email };

  const { data } = await api.post(`/reset-password/`, payload, { headers });
  return data;
};

export const resetPassword = async (token, password) => {
  const headers = {
    Accept: 'application/json',
  };

  const payload = { token, password };

  const { data } = await api.post(`/reset-password/confirm/`, payload, {
    headers,
  });
  return data;
};

export const getNotificacoes = async setorId => {
  if (setorId !== null && setorId !== '') {
    const params = { setor: setorId, ordering: 'cadastrado_em', lida: false };
    return fetchResponse('/notificacoes', params);
  }
  return [];
};

export const readNotificacao = async notificacaoId => {
  return fetchResponse(`/notificacoes/${notificacaoId}`);
};

export const getDefensores = async () => {
  return fetchResponse('/defensores');
};

export const getTiposArquivo = async defensoriaId => {
  const params = { defensoria: defensoriaId };
  return fetchResponse('/tipos-arquivo', params);
};

export const downloadSolicitacaoAllFiles = async solicitacaoId => {
  const apiToken = localStorage.getItem('@sid.apiToken');

  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${apiToken}`,
  };

  const response = await api.get(`/solicitacoes/${solicitacaoId}/download/`, {
    headers,
    responseType: 'arraybuffer',
  });
  return response.data;
};

export const reabrirSolicitacao = async ({ solicitacaoId, setorCriacao }) => {
  const apiToken = localStorage.getItem('@sid.apiToken');

  const headers = {
    Accept: 'application/json',
    Authorization: `Token ${apiToken}`,
  };

  return api.get(`/solicitacoes/${solicitacaoId}/reabrir/`, {
    headers,
    params: {
      setor_criacao: setorCriacao,
    },
  });
};
