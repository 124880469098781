import * as Sentry from '@sentry/react';
import { createContext, useContext, useState } from 'react';

import { getToken } from '../services/api';

import { useSetorAtual } from './ProviderSetorAtual';
import { TokenService } from '../services/tokenService';

const authService = {
  isAutheticated: false,

  async signin(username, password) {
    const userInfo = await getToken(username, password);
    authService.isAutheticated = true;

    return userInfo;
  },

  signout() {
    authService.isAutheticated = false;
  },
};

const authContext = createContext();

export function ProviderAuth({ children }) {
  const auth = useProviderAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useProviderAuth() {
  const { changeSetorAtual } = useSetorAtual();

  const [user, setUser] = useState(() => {
    return TokenService.getUser();
  });

  const signin = async (username, password) => {
    try {
      const authResponse = await authService.signin(username, password);

      const { user: userInfo, token } = authResponse;

      localStorage.setItem('@sid.user', JSON.stringify(userInfo));
      localStorage.setItem('@sid.apiToken', token);

      setUser(userInfo);

      return authResponse;
    } catch (ex) {
      Sentry.captureException(ex);

      localStorage.removeItem('@sid.apiToken');
      localStorage.removeItem('@sid.user');
      localStorage.removeItem('@sid.simulate');
      changeSetorAtual('');
      localStorage.removeItem('@sid.setorAtual');

      throw ex;
    }
  };

  const signout = () => {
    authService.signout();

    localStorage.removeItem('@sid.apiToken');
    localStorage.removeItem('@sid.user');
    localStorage.removeItem('@sid.simulate');
    changeSetorAtual('');
    localStorage.removeItem('@sid.setorAtual');

    setUser(null);
  };

  return {
    user,
    signin,
    signout,
  };
}
