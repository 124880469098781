import {
  Box,
  Icon,
  IconButton,
  Progress,
  Tab,
  Table,
  TableCaption,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { Link as RouterLink } from 'react-router-dom';

import { FaExclamationCircle, FaEye } from 'react-icons/fa';

import { useSetorAtual } from '../../context/ProviderSetorAtual';
import { useGetSolicitacoesPorSituacaoQuery } from '../../services/sidApi';
import { pollingInterval } from '../../utils';
import { formatDateTime } from '../../utils/formatDate';

import removerEventosSolicitacoesRepetidas from './removerEventosSolicitacoesRepetidas';

const SITUACOES_EVENTO = {
  NOVAS: 1,
  EM_ANALISE: 2,
  DEVOLVIDAS: 3,
};

const SITUACOES_EVENTO_BG_COLOR = {
  Devolvidas: 'green',
};

export default function PrateleiraSolicitacoesEnviadas(props) {
  const { setorAtual } = useSetorAtual();

  const { data: { results: solicitacoesPendentes = [] } = {}, isFetching } =
    useGetSolicitacoesPorSituacaoQuery(
      { setor_criacao: setorAtual, situacao: SITUACOES_EVENTO.NOVAS },
      {
        pollingInterval: pollingInterval(),
        skip: setorAtual === null || setorAtual === '',
      },
    );

  const { data: { results: solicitacoesEmAnalise = [] } = {} } =
    useGetSolicitacoesPorSituacaoQuery(
      {
        setor_criacao: setorAtual,
        situacao: SITUACOES_EVENTO.EM_ANALISE,
        ordering: 'modificado_em',
      },
      {
        pollingInterval: pollingInterval(),
        skip: setorAtual === null || setorAtual === '',
      },
    );

  const { data: { results: solicitacoesDevolvidas = [] } = {} } =
    useGetSolicitacoesPorSituacaoQuery(
      {
        setor_criacao: setorAtual,
        situacao: SITUACOES_EVENTO.DEVOLVIDAS,
        baixado: false,
        ordering: '-modificado_em',
      },
      {
        pollingInterval: pollingInterval(),
        skip: setorAtual === null || setorAtual === '',
      },
    );

  const { data: { results: solicitacoesBaixadas = [] } = {} } =
    useGetSolicitacoesPorSituacaoQuery(
      {
        setor_criacao: setorAtual,
        situacao: SITUACOES_EVENTO.DEVOLVIDAS,
        baixado: true,
        ordering: '-modificado_em',
      },
      {
        pollingInterval: pollingInterval(),
        skip: setorAtual === null || setorAtual === '',
      },
    );

  const prateleiraSolicitacoes = {
    Devolvidas: removerEventosSolicitacoesRepetidas(solicitacoesDevolvidas),
    Pendentes: removerEventosSolicitacoesRepetidas(solicitacoesPendentes),
    'Em Análise': removerEventosSolicitacoesRepetidas(solicitacoesEmAnalise),
    Baixadas: removerEventosSolicitacoesRepetidas(solicitacoesBaixadas),
  };

  return (
    <Box {...props}>
      <Text fontWeight="bold" fontSize="lg">
        Solicitações Enviadas
      </Text>

      <Tabs>
        <TabList>
          {Object.keys(prateleiraSolicitacoes).map(tipo => (
            <Tab key={tipo}>
              <Text mr="1">{tipo}</Text>
              <Tag
                borderRadius="full"
                colorScheme={SITUACOES_EVENTO_BG_COLOR[tipo]}
              >
                {prateleiraSolicitacoes[tipo].length}
              </Tag>
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          {Object.entries(prateleiraSolicitacoes).map(
            ([tipo, solicitacoes]) => (
              <TabPanel key={tipo}>
                <Progress size="xs" isIndeterminate={isFetching} />

                <Box border="1px solid" borderColor="gray.300" p="3">
                  <Box overflowX="auto">
                    <Table>
                      <TableCaption>{tipo}</TableCaption>

                      <Thead>
                        <Tr>
                          <Th>Nº</Th>
                          <Th>Destino</Th>
                          <Th>Pessoa Assistida</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>

                      <Tbody>
                        {solicitacoes?.map(solicitacao => (
                          <Tr key={solicitacao.id}>
                            <Td>
                              <Box>
                                {solicitacao.solicitacao.eh_urgente && (
                                  <Tag mb="1" colorScheme="red">
                                    <TagLeftIcon as={FaExclamationCircle} />
                                    <TagLabel>Urgente</TagLabel>
                                  </Tag>
                                )}
                              </Box>
                              <Tag>{solicitacao.solicitacao?.numero}</Tag>
                              <small>
                                {formatDateTime(
                                  solicitacao.modificado_em,
                                  true,
                                )}
                              </small>
                            </Td>
                            <Td>{solicitacao.setor_encaminhado?.nome}</Td>
                            <Td>{solicitacao.solicitacao?.pessoa?.nome}</Td>
                            <Td>
                              <RouterLink
                                to={`/solicitacoes/${solicitacao.solicitacao.id}`}
                              >
                                <IconButton
                                  icon={<Icon as={FaEye} />}
                                  title="Visualizar"
                                />
                              </RouterLink>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </Box>
              </TabPanel>
            ),
          )}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
