import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';

import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';

import {
  FaArrowAltCircleLeft,
  FaExclamationCircle,
  FaSave,
  FaTimes,
} from 'react-icons/fa';

import * as Sentry from '@sentry/react';
import { isNil } from 'lodash';

import OverlaySpinner from '../../components/OverlaySpinner';
import UploadDocumentos, {
  getFileIcon,
} from '../../components/UploadDocumentos';
import { useSetorAtual } from '../../context/ProviderSetorAtual';
import useFetchTiposArquivos from '../../hooks/useFetchTiposArquivos';

import HtmlEditor from '../../components/HtmlEditor';

import {
  getResponderOutroEventoOpcoes,
  getSetor,
  getSetoresParaEncaminhamento,
  getSolicitacaoPorId,
  getTiposDocumento,
  getTiposEventoPorSolicitacao,
  salvarEvento,
  uploadDocument,
} from '../../services/api';

export default function NovoEvento() {
  const { id: solicitacaoId } = useParams();
  const { setorAtual } = useSetorAtual();
  const navigate = useNavigate();
  const toast = useToast();

  const [solicitacao, setSolicitacao] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState({ data: null, message: '' });

  const [formIsDisabled, setFormIsDisabled] = useState(false);

  useEffect(() => {
    const fetchSolicitacao = async () => {
      try {
        setIsLoading(true);

        const data = await getSolicitacaoPorId(solicitacaoId);
        setSolicitacao(data);
      } catch (ex) {
        Sentry.captureException(ex);

        setError({ data: null, message: ex.message });
      } finally {
        setIsLoading(false);
      }
    };

    if (solicitacaoId != null) {
      fetchSolicitacao();
    }
  }, [solicitacaoId]);

  useEffect(() => {
    if (solicitacao == null) {
      return;
    }

    const novoEventoIsDisabled = solicitacao?.baixada_em != null;

    if (novoEventoIsDisabled) {
      navigate(`/solicitacoes/${solicitacao.id}`);
    }
  }, [navigate, solicitacao]);

  const [responderOutroEventoOpcoes, setResponderOutroEventoOpcoes] = useState(
    [],
  );

  useEffect(() => {
    const fetchResponderOutroEventoOpcoes = async () => {
      if (setorAtual !== '') {
        const setorEncaminhado = setorAtual;

        const data = await getResponderOutroEventoOpcoes(
          solicitacaoId,
          setorEncaminhado,
        );
        setResponderOutroEventoOpcoes(data);
        if (data.length > 0) {
          setResponderOutroEvento(data[0].id);
        }
      } else {
        setResponderOutroEventoOpcoes([]);
      }
    };

    fetchResponderOutroEventoOpcoes();

    return function cleanup() {
      setResponderOutroEventoOpcoes([]);
    };
  }, [setorAtual, solicitacaoId]);

  const [
    setorParaEncaminhamentoIsDisabled,
    setSetorParaEncaminhamentoIsDisabled,
  ] = useState(false);

  const [responderOutroEvento, setResponderOutroEvento] = useState('');

  const handleChangeResponderOutroEvento = event =>
    setResponderOutroEvento(event.target.value);

  useEffect(() => {
    setSetorParaEncaminhamentoIsDisabled(responderOutroEvento !== '');
  }, [responderOutroEvento]);

  const [tipoEvento, setTipoEvento] = useState('');
  const handleChangeTipoEvento = event => setTipoEvento(event.target.value);

  const [tiposEvento, setTiposEvento] = useState([]);

  useEffect(() => {
    const fetchTiposEvento = async () => {
      if (setorAtual !== '' && !Number.isNaN(solicitacaoId)) {
        const params = { setor_criacao: setorAtual };

        const data = await getTiposEventoPorSolicitacao(solicitacaoId, params);

        setTiposEvento(data);
        setTipoEvento(data[0]?.id);
      } else {
        setTiposEvento([]);
        setTipoEvento('');
      }
    };

    fetchTiposEvento();
  }, [setorAtual, solicitacaoId]);

  const [setorParaEncaminhamento, setSetorParaEncaminhamento] = useState('');
  const handleChangeSetorParaEncaminhamento = event =>
    setSetorParaEncaminhamento(event.target.value);

  const [setoresParaEncaminhamento, setSetoresParaEncaminhamento] = useState(
    [],
  );

  useEffect(() => {
    const fetchSetoresParaEncaminhamento = async () => {
      if (setorAtual !== '' && solicitacao != null) {
        const data = await getSetoresParaEncaminhamento(
          solicitacao.id,
          setorAtual,
        );

        setSetoresParaEncaminhamento(data);
        setSetorParaEncaminhamento(data[0]?.id);
      } else {
        setSetoresParaEncaminhamento([]);
        setSetorParaEncaminhamento('');
      }
    };

    fetchSetoresParaEncaminhamento();
  }, [setorAtual, solicitacao]);

  const [defensoriaDestino, setDefensoriaDestino] = useState(null);
  useEffect(() => {
    const fetchDefensoriaDestino = async () => {
      if (setorParaEncaminhamento !== '') {
        const data = await getSetor(setorParaEncaminhamento);
        setDefensoriaDestino(data.defensoria);
      }
    };

    fetchDefensoriaDestino();
  }, [setorParaEncaminhamento]);

  const [historico, setHistorico] = useState();

  const handleHtmlContentChange = newContent => {
    setHistorico(newContent);
  };

  // defensoriaDestinoId é o id da defensoria ao qual pertence o setor
  // para encaminhamento
  const tiposArquivo = useFetchTiposArquivos(defensoriaDestino);

  const [documentos, setDocumentos] = useState([]);

  const TIPO_DOCUMENTO_PADRAO = '5';

  const onDropDocuments = useCallback(
    acceptedFiles => {
      const newDocumentos = acceptedFiles.map(f => ({
        tipo: TIPO_DOCUMENTO_PADRAO,
        file: f,
      }));

      setDocumentos([...documentos, ...newDocumentos]);
    },
    [documentos],
  );

  const onRemoveDocumento = (documento, index) => {
    setDocumentos(documentos.filter(d => d.file.name !== documento.file.name));

    setError({
      ...error,
      data: {
        arquivo: error.data.arquivo.filter(
          (_, arquivoIndex) => arquivoIndex !== index,
        ),
      },
    });
  };

  const handleChangeTipoDocumento = (event, documento) => {
    setDocumentos(
      documentos.map(d => {
        return d.file.name === documento.file.name
          ? { ...d, tipo: event.target.value }
          : d;
      }),
    );
  };

  useEffect(() => {
    if (documentos.length) {
      setFormIsDisabled(!documentos.every(d => d.tipo !== ''));
    }
  }, [documentos]);

  const [tiposDocumento, setTiposDocumento] = useState([]);

  useEffect(() => {
    const fetchTiposDocumento = async () => {
      const data = await getTiposDocumento();
      setTiposDocumento(data);
    };

    fetchTiposDocumento();
  }, []);

  async function uploadDocumentosToServer(eventoId) {
    for (const documento of documentos) {
      const formData = new FormData();

      formData.append('nome', documento.file.name);
      formData.append('arquivo', documento.file, documento.file.name);
      formData.append('tipo', parseInt(documento.tipo, 10));
      formData.append('evento', parseInt(eventoId, 10));

      // eslint-disable-next-line no-await-in-loop
      await uploadDocument(formData);
    }
  }

  const [overlayIsOpen, setOverlayIsOpen] = useState(false);

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  let defensor;

  if (solicitacao.eventos.length) {
    const { nome } = solicitacao.eventos[0].cadastrado_por;
    const setor = solicitacao.eventos[0].setor_criacao.nome;

    defensor = {
      nome,
      username: solicitacao.eventos[0].cadastrado_por.username,
      email: solicitacao.eventos[0].cadastrado_por.email,
      setor,
      toString() {
        return `${nome} (${setor})`;
      },
    };
  }

  const payload = {
    solicitacao_id: parseInt(solicitacao.id, 10),
    setor_criacao_id: parseInt(setorAtual, 10),
    setor_encaminhado_id: setorParaEncaminhamento,
    historico,
    tipo: tipoEvento,
    evento_pai:
      responderOutroEvento === '' ? null : parseInt(responderOutroEvento, 10),
  };

  const handleSalvar = async () => {
    if (formIsDisabled) {
      return;
    }

    if (setorParaEncaminhamentoIsDisabled) {
      delete payload.setor_encaminhado_id;
    }

    try {
      setOverlayIsOpen(true);
      const { id } = await salvarEvento(payload);
      await uploadDocumentosToServer(id);
      setOverlayIsOpen(false);

      setFormIsDisabled(true);

      toast({
        title: 'Novo evento',
        description: 'Novo evento salvo com sucesso',
        status: 'success',
        duration: 2500,
        isClosable: true,
        onCloseComplete: () => navigate(`/solicitacoes/${solicitacaoId}`),
      });
    } catch (ex) {
      Sentry.setContext('POST_PAYLOAD', payload);
      Sentry.captureException(ex);

      let errorMessage = '';

      if (ex.isAxiosError && ex.response.status < 500) {
        errorMessage =
          'Verifique no formulário os campos que apresentaram erros.';
        setError({ data: ex.response.data, message: errorMessage });
      } else {
        errorMessage =
          'Entre em contato com o suporte técnico para resolver esse problema';
        setError({ message: errorMessage });
      }

      setOverlayIsOpen(false);

      toast({
        title: 'Novo evento',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <OverlaySpinner isOpen={overlayIsOpen} />

      <Container maxW="container.xl" p="2">
        <Flex
          direction={['column', null, 'row']}
          alignItems="center"
          mb="3"
          py="2"
        >
          <Heading size="lg" mb={['2', '2', '0']}>
            Novo Evento
          </Heading>

          <HStack ml={{ md: 'auto' }}>
            <Button
              as={RouterLink}
              to={`/solicitacoes/${solicitacaoId}`}
              leftIcon={<Icon as={FaArrowAltCircleLeft} />}
              colorScheme="blackAlpha"
            >
              Voltar
            </Button>
          </HStack>
        </Flex>

        <Grid gap="3" templateColumns={{ lg: '20rem 1fr' }}>
          <VStack spacing="2">
            <Box
              w="full"
              px="10"
              py="6"
              textAlign="center"
              fontWeight="bold"
              bgColor="yellow.300"
            >
              Solicitação nº {solicitacao.numero}
            </Box>

            {solicitacao.eh_urgente && (
              <Flex
                w="full"
                px="10"
                py="6"
                bgColor="red.300"
                textColor="white"
                fontWeight="bold"
                alignItems="center"
                justify="center"
              >
                <Icon as={FaExclamationCircle} fontSize="2xl" mr="1" />
                <span>Urgente</span>
              </Flex>
            )}

            <Box w="full" h="full" bg="gray.300" p="4">
              <dl>
                <chakra.dt fontWeight="bold">Origem:</chakra.dt>
                <chakra.dd mb="3">
                  {solicitacao.defensoria_origem?.nome}
                </chakra.dd>

                <chakra.dt fontWeight="bold">Destino:</chakra.dt>
                <chakra.dd mb="3">
                  {solicitacao.defensoria_destino?.nome}
                </chakra.dd>

                <chakra.dt fontWeight="bold">Localidade:</chakra.dt>
                <chakra.dd mb="3">{solicitacao.localidade?.nome}</chakra.dd>

                <chakra.dt fontWeight="bold">Área:</chakra.dt>
                <chakra.dd mb="3">{solicitacao.area?.nome}</chakra.dd>

                <chakra.dt fontWeight="bold">Tipo:</chakra.dt>
                <chakra.dd mb="3">{solicitacao.tipo?.nome}</chakra.dd>

                <chakra.dt fontWeight="bold">Processo:</chakra.dt>
                <chakra.dd mb="3">{solicitacao.processo?.numero}</chakra.dd>

                <chakra.dt fontWeight="bold">Pessoa Assistida:</chakra.dt>
                <chakra.dd mb="3">{solicitacao.pessoa?.nome}</chakra.dd>

                <chakra.dt fontWeight="bold">Defensor(a):</chakra.dt>
                <chakra.dd mb="3">{defensor.toString()}</chakra.dd>
              </dl>
            </Box>
          </VStack>

          <Box border="1px solid" borderColor="gray.300" p="4">
            <FormControl id="responder_outro_evento" mb="3">
              <FormLabel>Responder a um evento?</FormLabel>

              <Select
                onChange={handleChangeResponderOutroEvento}
                value={responderOutroEvento}
              >
                <option value="">Não</option>
                {responderOutroEventoOpcoes.map(evento => (
                  <option
                    key={evento.id}
                    value={evento.id}
                  >{`# ${evento.numero} (${evento.setor_criacao.nome})`}</option>
                ))}
              </Select>
            </FormControl>

            <FormControl id="tipo" mb="3">
              <FormLabel>Tipo:</FormLabel>

              <Select value={tipoEvento} onChange={handleChangeTipoEvento}>
                {tiposEvento.map(tipoEventoEl => (
                  <option key={tipoEventoEl.id} value={tipoEventoEl.id}>
                    {tipoEventoEl.nome}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl id="encaminhamento_destino" mb="3">
              <FormLabel>Encaminhar para:</FormLabel>

              <Select
                value={setorParaEncaminhamento}
                onChange={handleChangeSetorParaEncaminhamento}
                disabled={setorParaEncaminhamentoIsDisabled}
              >
                {setoresParaEncaminhamento.map(setor => (
                  <option key={setor.id} value={setor.id}>
                    {setor.nome}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl id="anotacoes" mb="3">
              <FormLabel>Anotações:</FormLabel>

              <HtmlEditor onContentChange={handleHtmlContentChange} />
            </FormControl>

            <UploadDocumentos
              onDropDocuments={onDropDocuments}
              documentos={documentos}
              isDisabled={formIsDisabled}
              restrictions={tiposArquivo}
            />

            {documentos.length > 0 && (
              <Box
                border="1px solid"
                borderColor="gray.300"
                borderRadius="lg"
                p="5"
                mb="3"
              >
                {documentos.map((documento, index) => (
                  <Flex
                    key={`${documento.file.name}-${documento.file.lastModified}`}
                    alignItems={documento.tipo === '' ? 'flex-start' : 'center'}
                    mb="3"
                  >
                    <Flex alignItems="center" flex="1" mr="3">
                      <Icon
                        as={getFileIcon(documento.file.name)}
                        color="red.500"
                        me="2"
                        fontSize="xl"
                      />

                      <Box
                        w="full"
                        textColor={
                          isNil(error?.data?.arquivo[index]) ? '' : 'red.500'
                        }
                      >
                        <Text>{documento.file.name}</Text>
                        {error?.data?.arquivo[index] && (
                          <Text>
                            <Icon as={FaExclamationCircle} me="1" />
                            <chakra.span verticalAlign="middle">
                              {error?.data?.arquivo[index]}
                            </chakra.span>
                          </Text>
                        )}
                      </Box>

                      <FormControl>
                        <Select
                          onChange={event =>
                            handleChangeTipoDocumento(event, documento)
                          }
                          value={documento.tipo}
                        >
                          {tiposDocumento.map(tipo => (
                            <option key={tipo.id} value={tipo.id}>
                              {tipo.nome}
                            </option>
                          ))}
                        </Select>

                        {documento.tipo === '' && (
                          <FormHelperText color="red">
                            O tipo do documento é obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Flex>

                    <IconButton
                      icon={<Icon as={FaTimes} fontSize="sm" />}
                      onClick={() => onRemoveDocumento(documento, index)}
                    />
                  </Flex>
                ))}
              </Box>
            )}

            <HStack ml={{ md: 'auto' }} justifyContent="flex-end">
              <Button
                as={RouterLink}
                to={`/solicitacoes/${solicitacaoId}`}
                leftIcon={<Icon as={FaTimes} />}
                colorScheme="blackAlpha"
              >
                Cancelar
              </Button>

              <Button
                leftIcon={<Icon as={FaSave} />}
                colorScheme="green"
                onClick={handleSalvar}
                disabled={formIsDisabled}
              >
                Salvar
              </Button>
            </HStack>
          </Box>
        </Grid>
      </Container>
    </>
  );
}
