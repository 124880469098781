import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getTiposEmail } from '../../services/api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchTiposEmail = createAsyncThunk(
  'tiposEmail/fetchTiposEmail',
  async () => {
    return getTiposEmail();
  },
);

export const tiposEmailSlice = createSlice({
  name: 'tiposEmail',

  initialState,

  extraReducers: {
    [fetchTiposEmail.pending]: state => {
      state.status = 'loading';
    },

    [fetchTiposEmail.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },

    [fetchTiposEmail.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default tiposEmailSlice.reducer;

export const selectAllTiposEmail = state => state.tiposEmail.data;
