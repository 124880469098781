import { useMemo } from 'react';

import { Box, Progress, Text } from '@chakra-ui/react';

import CalendarioEventos from '../../components/CalendarioEventos';
import { useSetorAtual } from '../../context/ProviderSetorAtual';
import { useGetEventosTotaisPorDataQuery } from '../../services/sidApi';
import { months, pollingInterval } from '../../utils';

export default function AtividadesMes(props) {
  const { setorAtual: setorCriacao } = useSetorAtual();

  const currentDate = useMemo(() => {
    const localDate = new Date();

    return {
      date: localDate,
      toString() {
        return `(${months[localDate.getMonth()]}/${localDate.getFullYear()})`;
      },
    };
  }, []);

  const ano = currentDate.date.getFullYear();
  const mes = currentDate.date.getMonth() + 1;

  const { data: eventosTotaisPorData = [], isFetching } =
    useGetEventosTotaisPorDataQuery(
      { ano, mes, setor_criacao: setorCriacao },
      {
        pollingInterval: pollingInterval(),
        skip: setorCriacao === null || setorCriacao === '',
      },
    );

  const eventosTotaisTransformed = {};

  eventosTotaisPorData.forEach(e => {
    if (e.data_referencia in eventosTotaisTransformed) {
      eventosTotaisTransformed[e.data_referencia] += e.total;
    } else {
      eventosTotaisTransformed[e.data_referencia] = e.total;
    }
  });

  return (
    <Box {...props}>
      <Text fontWeight="bold" fontSize="lg" mb="3">
        Atividades do Mês {currentDate.toString()}
      </Text>
      <Progress size="xs" isIndeterminate={isFetching} />

      <CalendarioEventos
        currentDate={currentDate.date}
        events={eventosTotaisTransformed}
      />
    </Box>
  );
}
