// Esta função utilitária é para remover de um array de eventos os eventos
// que têm solicitações repetidas. Ou seja, se tivermos dois eventos da
// mesma solicitação, apenas um evento irá permanecer no array.

export default function removerEventosSolicitacoesRepetidas(eventos) {
  return eventos.reduce(
    (acc, cur) =>
      acc.some(el => el.solicitacao.id === cur.solicitacao.id)
        ? [...acc]
        : [...acc, cur],
    [],
  );
}
