import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import { getDefensorias } from '../services/api';

export default function useFetchDefensorias() {
  const [defensorias, setDefensorias] = useState([]);

  useEffect(() => {
    const fetchDefensorias = async () => {
      try {
        const data = await getDefensorias();
        setDefensorias(data);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchDefensorias();
  }, []);

  return defensorias;
}
