import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getSetores } from '../../services/api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchSetores = createAsyncThunk(
  'setores/fetchSetores',
  async () => {
    return getSetores();
  },
);

export const setoresSlice = createSlice({
  name: 'setores',

  initialState,

  extraReducers: {
    [fetchSetores.pending]: state => {
      state.status = 'loading';
    },

    [fetchSetores.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },

    [fetchSetores.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default setoresSlice.reducer;

export const selectAllSetores = state => state.setores.data;
