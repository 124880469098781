import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { getTiposTelefone } from '../../services/api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchTiposTelefone = createAsyncThunk(
  'tiposTelefone/fetchTiposTelefone',
  async () => {
    return getTiposTelefone();
  },
);

export const tiposTelefoneSlice = createSlice({
  name: 'tiposTelefone',

  initialState,

  extraReducers: {
    [fetchTiposTelefone.pending]: state => {
      state.status = 'loading';
    },

    [fetchTiposTelefone.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },

    [fetchTiposTelefone.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default tiposTelefoneSlice.reducer;

export const selectAllTiposTelefone = state => state.tiposTelefone.data;
