import { Icon, Text } from '@chakra-ui/react';
import { FaRegClock } from 'react-icons/fa';

export function formatDate(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('pt-BR', { dateStyle: 'short' }).format(date);
}

export function formatTime(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short' }).format(date);
}

export function formatDateTime(dateString, addIcon) {
  const date = new Date(dateString);

  const dateFormatted = new Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'short',
  }).format(date);
  const timeFormatted = new Intl.DateTimeFormat('pt-BR', {
    timeStyle: 'short',
  }).format(date);

  if (addIcon) {
    return (
      <Text>
        <Icon as={FaRegClock} /> {dateFormatted} às {timeFormatted}
      </Text>
    );
  }

  return (
    <>
      <Text>{dateFormatted}</Text>
      <Text color="gray.600">às {timeFormatted}</Text>
    </>
  );
}
