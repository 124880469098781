import { useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { FaEye, FaEyeSlash, FaLock, FaUser } from 'react-icons/fa';

import * as Sentry from '@sentry/react';

import { useAuth } from '../../context/ProviderAuth';

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const initialRoute = '/acompanhamento';

  const { from } = location.state || { from: { pathname: '/' } };

  const handleLogin = async () => {
    try {
      setError(null);
      setIsLoading(true);

      await auth.signin(username, password);

      if (from.pathname === '/') {
        navigate(initialRoute);
      } else {
        navigate(from);
      }
    } catch (ex) {
      Sentry.captureException(ex);

      setIsLoading(false);
      setError(ex.message);
    }
  };

  const handleClickShowPassword = () =>
    setShowPassword(previousState => !previousState);

  const handleChangeUsername = event => setUsername(event.target.value);
  const handleChangePassword = event => setPassword(event.target.value);

  return (
    <Center h="full" bg={useColorModeValue('gray.100', 'gray.800')} p="3">
      <Flex direction="column" alignItems="center">
        <Img src="/sid-logo.png" width="120px" height="81.10px" mb="3" />

        <Heading as="h2" mb="8" textAlign="center" fontSize="2xl">
          SID - Sistema Integrador das Defensorias Públicas
        </Heading>
        <Heading as="h3" mb="6" fontSize="3xl">
          Login
        </Heading>

        <Box
          shadow="md"
          bg="white"
          border="1px solid"
          borderColor="gray.300"
          p="5"
          borderRadius="lg"
        >
          <Stack spacing={4}>
            <FormControl id="username">
              <FormLabel color={useColorModeValue('inherit', 'gray.800')}>
                Usuário
              </FormLabel>

              <InputGroup>
                <InputLeftElement
                  color={useColorModeValue('inherit', 'gray.700')}
                  pointerEvents="none"
                >
                  <Icon as={FaUser} />
                </InputLeftElement>

                <Input
                  value={username}
                  onChange={handleChangeUsername}
                  onKeyDown={event =>
                    event.code === 'Enter' ? handleLogin() : null
                  }
                  isInvalid={error != null}
                  errorBorderColor="red.300"
                  placeholder="@mail.com"
                  color={useColorModeValue('inherit', 'gray.800')}
                />
              </InputGroup>
            </FormControl>

            <FormControl id="password">
              <FormLabel color={useColorModeValue('inherit', 'gray.800')}>
                Senha
              </FormLabel>

              <InputGroup size="md">
                <InputLeftElement
                  color={useColorModeValue('inherit', 'gray.700')}
                  pointerEvents="none"
                >
                  <Icon as={FaLock} />
                </InputLeftElement>

                <Input
                  value={password}
                  onChange={handleChangePassword}
                  onKeyDown={event =>
                    event.code === 'Enter' ? handleLogin() : null
                  }
                  isInvalid={error != null}
                  type={showPassword ? 'text' : 'password'}
                  pr="3.15rem"
                  errorBorderColor="red.300"
                  placeholder="Senha"
                  color={useColorModeValue('inherit', 'gray.800')}
                />
                <InputRightElement width="3.15rem">
                  <Button
                    h="1.75rem"
                    size="sm"
                    onClick={handleClickShowPassword}
                    color={useColorModeValue('inherit', 'gray.700')}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Button
              isLoading={isLoading}
              loadingText="Loading"
              colorScheme="green"
              variant="solid"
              spinnerPlacement="start"
              onClick={handleLogin}
            >
              <Text as="span">Entrar</Text>
            </Button>

            {error && (
              <Text color="red.400" fontWeight="bold">
                Falha na autenticação
              </Text>
            )}

            <Box>
              <Link
                as={RouterLink}
                to="/recuperar-senha"
                color="green.600"
                fontWeight="bold"
                fontSize="sm"
              >
                Esqueci minha senha
              </Link>
            </Box>
          </Stack>
        </Box>
      </Flex>
    </Center>
  );
}
