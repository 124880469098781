import { Badge, Box, Flex, SimpleGrid } from '@chakra-ui/react';

export default function CalendarioEventos({
  currentDate = new Date(),
  events = {},
}) {
  const getDatesBetweenDates = (startDate, endDate) => {
    let dates = [];

    const theDate = new Date(startDate);

    while (theDate <= endDate) {
      dates = [...dates, new Date(theDate)];
      theDate.setDate(theDate.getDate() + 1);
    }

    return dates;
  };

  const isDateInThisMonth = date => date.getMonth() === currentDate.getMonth();

  const formatDate = date => new Intl.DateTimeFormat('pt-BR').format(date);

  let firstDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1,
  );
  firstDate =
    firstDate.getDay() === 0
      ? firstDate
      : new Date(
          firstDate.getFullYear(),
          firstDate.getMonth(),
          firstDate.getDate() - firstDate.getDay(),
        );

  let lastDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0,
  );
  lastDate =
    lastDate.getDay() === 6
      ? lastDate
      : new Date(
          lastDate.getFullYear(),
          lastDate.getMonth(),
          lastDate.getDate() + (6 - lastDate.getDay()),
        );

  const datesRange = getDatesBetweenDates(firstDate, lastDate);

  const eventsRendered = date => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, 0);
    const day = date.getDate().toString().padStart(2, 0);
    const key = `${year}-${month}-${day}`;

    if (key in events) {
      return (
        <Badge colorScheme="green" px="2.5">
          {events[key]}
        </Badge>
      );
    }
    return '';
  };

  const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  const borderColor = 'gray.300';

  const formatCell = {
    borderBottom: '1px solid',
    borderBottomColor: borderColor,
    borderRight: '1px solid',
    borderRightColor: borderColor,
    px: '1',
    py: '2',
    h: '14',
    flexDir: 'column',
    alignItems: 'center',
    justify: 'center',
  };

  return (
    <Box>
      <SimpleGrid
        templateColumns="repeat(7, 1fr)"
        borderTop="1px solid"
        borderTopColor={borderColor}
        borderLeft="1px solid"
        borderLeftColor={borderColor}
      >
        {weekDays.map(weekDay => (
          <Flex
            key={weekDay}
            {...formatCell}
            bgColor="gray.100"
            fontWeight="bold"
          >
            {weekDay}
          </Flex>
        ))}

        {datesRange.map(date => (
          <Flex key={formatDate(date)} {...formatCell} pos="relative">
            <Box
              position="absolute"
              top="2"
              right="1.5"
              fontSize="xs"
              textColor={isDateInThisMonth(date) ? 'gray.500' : 'gray.300'}
            >
              {date.getDate()}
            </Box>

            {eventsRendered(date)}
          </Flex>
        ))}
      </SimpleGrid>
    </Box>
  );
}
