export const INFO =
  'background: teal; color: white; padding: 0.25rem; border-radius: 4px; font-weight: bold;';

export const copy = data => JSON.parse(JSON.stringify(data));

export function deleteKeyFromObject(obj, key) {
  return Object.fromEntries(Object.entries(obj).filter(el => el[0] !== key));
}

export const months = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const pollingInterval = () => {
  const localValue = localStorage.getItem('@sid.pollingInterval');

  if (localValue !== null && localValue !== '') {
    return parseInt(localValue, 10);
  }
  return 5 * 60 * 1000; // minutos * segundos * milissegundos
};
