import { Box, Icon, IconButton } from '@chakra-ui/react';
import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { FaCode } from 'react-icons/fa';

export default function HtmlEditor({ onContentChange }) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );

  const handleEditorStateChange = newEditorState => {
    setEditorState(newEditorState);
  };

  useEffect(() => {
    const raw = convertToRaw(editorState.getCurrentContent());
    const html = draftToHtml(raw);
    onContentChange(html);
  }, [editorState, onContentChange]);

  const [stripPastedStyles, setStripPastedStyles] = useState(true);
  const toggleStripPastedStyles = () => setStripPastedStyles(prev => !prev);

  return (
    <Box
      className="html-editor"
      border="1px solid"
      borderColor="gray.200"
      sx={{
        ' & .DraftEditor-editorContainer': { px: 5 },
      }}
    >
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={handleEditorStateChange}
        stripPastedStyles={stripPastedStyles}
        toolbarCustomButtons={[
          <IconButton
            aria-label="Alternar modo de copiar"
            title={
              stripPastedStyles
                ? 'Remover estilos da área de transferência'
                : 'Preservar estilos da área de transferência'
            }
            onClick={toggleStripPastedStyles}
            icon={<Icon as={FaCode} />}
            size="sm"
            mb="1"
            colorScheme={stripPastedStyles ? 'gray' : 'blackAlpha'}
          />,
        ]}
      />
    </Box>
  );
}
