import { Box, Center, FormLabel, Icon, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';

import { FaFile, FaFilePdf, FaFileWord, FaUpload } from 'react-icons/fa';

export const getFileIcon = filename => {
  const match = filename.match(/(?!\.)\w+$/);

  if (match.length) {
    const extension = match[0];

    switch (extension) {
      case 'pdf':
        return FaFilePdf;
      case 'doc':
        return FaFileWord;
      case 'docx':
        return FaFileWord;
      case 'txt':
        return FaFile;
      default:
        return FaFile;
    }
  }

  return FaFile;
};

export default function UploadDocumentos({
  onDropDocuments,
  isDisabled,
  restrictions,
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropDocuments,
    disabled: isDisabled,
  });

  return (
    <Box mb="3">
      <FormLabel>Documentos</FormLabel>

      {restrictions.length > 0 && (
        <Box
          mb="3"
          border="1px dashed"
          borderColor="gray.300"
          bgColor="orange.100"
          p="2"
        >
          <Text fontWeight="bold">Orientações:</Text>

          {restrictions.map(
            ({ id, formato, tamanhoMaximo, deveAssinarComCertificado }) => (
              <Text key={id}>
                <Text as="span" fontWeight="bold">
                  {formato}:
                </Text>{' '}
                Limite {tamanhoMaximo} MB
                {deveAssinarComCertificado
                  ? ', assinado com certificado digital'
                  : null}
              </Text>
            ),
          )}
        </Box>
      )}

      <Center
        h="150"
        border="1px dashed"
        borderColor="gray.300"
        borderRadius="lg"
        bgColor={isDragActive ? 'gray.300' : 'gray.50'}
        {...getRootProps()}
        mb="3"
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Box>Solte os arquivos aqui ...</Box>
        ) : (
          <Box textAlign="center">
            <Icon as={FaUpload} fontSize="xx-large" />
            <Text>Arraste arquivos</Text>
          </Box>
        )}
      </Center>
    </Box>
  );
}
