import * as Sentry from '@sentry/react';
import { useEffect } from 'react';

import { useAuth } from './context/ProviderAuth';
import { useSetorAtual } from './context/ProviderSetorAtual';
import Routes from './routes';
import { getSetorAtual } from './services/api';

export default function App() {
  const auth = useAuth();
  const { changeSetorAtual } = useSetorAtual();

  useEffect(() => {
    const fetchSetorAtual = async () => {
      try {
        if (auth.user) {
          const { id: setorAtualId } = await getSetorAtual(auth.user.id);
          changeSetorAtual(setorAtualId);
        }
      } catch (ex) {
        Sentry.captureException(ex);

        changeSetorAtual('');
      }
    };

    fetchSetorAtual();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.user]);

  return <Routes />;
}
