import {
  Center,
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react';

export default function OverlaySpinner({ isOpen, onClose }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />

      <ModalContent bgColor="transparent" shadow="none">
        <Center h="full">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </ModalContent>
    </Modal>
  );
}
