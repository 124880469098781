import React from 'react';
import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ExtraErrorData } from '@sentry/integrations';

import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';

import { Provider } from 'react-redux';
import store from './store';

import App from './App';

import theme from './theme';

import { ProviderAuth } from './context/ProviderAuth';
import { ProviderSetorAtual } from './context/ProviderSetorAtual';

import './styles/main.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment:
    process.env.REACT_APP_APPLICATION_ENV === 'homolog'
      ? 'homologation'
      : 'production',
  integrations: [
    new Integrations.BrowserTracing(),
    new ExtraErrorData({ depth: 9 }),
  ],
  normalizeDepth: 10,
  tracesSampleRate: 0.1,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />

    <ChakraProvider theme={theme}>
      <ProviderSetorAtual>
        <ProviderAuth>
          <Provider store={store}>
            <App />
          </Provider>
        </ProviderAuth>
      </ProviderSetorAtual>
    </ChakraProvider>
  </>,
);
