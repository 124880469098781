import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Input,
  Select,
  Switch,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react';

import {
  FaArrowAltCircleLeft,
  FaExclamationCircle,
  FaMinus,
  FaPlus,
  FaSave,
  FaTimes,
} from 'react-icons/fa';

import * as Sentry from '@sentry/react';
import Cleave from 'cleave.js/react';
import { isNil } from 'lodash';

import UploadDocumentos, {
  getFileIcon,
} from '../../components/UploadDocumentos';
import { useSetorAtual } from '../../context/ProviderSetorAtual';

import {
  getDefensores,
  getSetor,
  getSetores,
  getSolicitacaoPorId,
  getTiposDocumento,
  salvarSolicitacao,
  uploadDocument,
} from '../../services/api';

import OverlaySpinner from '../../components/OverlaySpinner';
import { useAuth } from '../../context/ProviderAuth';
import useFetchTiposArquivos from '../../hooks/useFetchTiposArquivos';
import { copy } from '../../utils';

import {
  fetchDefensorias,
  selectAllDefensorias,
} from '../defensorias/defensoriasSlice';

import {
  fetchLocalidadesPorDefensoria,
  selectLocalidadesPorDefensoria,
} from '../localidades/localidadesSlice';

import { fetchTiposEmail, selectAllTiposEmail } from './tiposEmailSlice';

import {
  fetchTiposSolicitacao,
  selectAllTiposSolicitacao,
} from './tiposSolicitacaoSlice';

import HtmlEditor from '../../components/HtmlEditor';
import {
  fetchTiposTelefone,
  selectAllTiposTelefone,
} from './tiposTelefoneSlice';

const TIPOS_SETORES = {
  GABINETE_GERAL: 1,
  GABINETE_DEFENSOR: 2,
  ADMINISTRATIVO: 3,
};

export default function NovaSolicitacao() {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const toast = useToast();
  const { setorAtual } = useSetorAtual();
  const auth = useAuth();

  const defensorias = useSelector(selectAllDefensorias);
  const defensoriasStatus = useSelector(state => state.defensorias.status);

  const [defensoriaId, setDefensoriaId] = useState('');

  const localidades = useSelector(selectLocalidadesPorDefensoria);
  const localidadesStatus = useSelector(state => state.localidades.status);

  const [localidade, setLocalidade] = useState(null);
  const [localidadeId, setLocalidadeId] = useState('');

  const [areas, setAreas] = useState([]);
  const [areaId, setAreaId] = useState('');

  const [tipoId, setTipoId] = useState('');

  const [urgente, setUrgente] = useBoolean(false);

  const [processo, setProcesso] = useState(null);

  const [setorAtendimentoId, setSetorAtendimentoId] = useState('');

  const [pessoa, setPessoa] = useState({
    cpf_cnpj: '',
    nome: '',
    telefones: [
      { id: new Date().valueOf(), tipo: 1, numero: '', complemento: '' },
    ],
    emails: [{ id: new Date().valueOf(), tipo: 1, email: '', complemento: '' }],
  });

  const [setorOrigem, setSetorOrigem] = useState(null);

  useEffect(() => {
    const fetchSetorOrigem = async () => {
      if (setorAtual !== null && setorAtual !== '') {
        const data = await getSetor(setorAtual);
        setSetorOrigem(data);
      } else {
        setSetorOrigem(null);
      }
    };

    fetchSetorOrigem();
  }, [setorAtual]);

  useEffect(() => {
    if (defensoriasStatus === 'idle') {
      dispatch(fetchDefensorias());
    } else if (defensoriasStatus === 'succeeded') {
      setDefensoriaId(defensorias[0]?.id ?? '');
    }
  }, [defensorias, defensoriasStatus, dispatch]);

  useEffect(() => {
    if (defensoriaId != null && defensoriaId !== '') {
      dispatch(fetchLocalidadesPorDefensoria(defensoriaId));
    }
  }, [defensoriaId, dispatch]);

  const handleChangeDefensoria = event => {
    setDefensoriaId(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    if (localidadesStatus === 'succeeded') {
      setLocalidade(localidades[0]);
      setLocalidadeId(localidades[0]?.id ?? '');
    }
  }, [localidades, localidadesStatus]);

  useEffect(() => {
    if (localidade != null) {
      setAreas(localidade.areas);
      setAreaId(localidade.areas[0]?.id ?? '');
    } else {
      setAreas([]);
      setAreaId('');
    }
  }, [localidade]);

  const handleChangeLocalidade = event => {
    setLocalidade(
      localidades.find(el => el.id === parseInt(event.target.value, 10)),
    );
    setLocalidadeId(parseInt(event.target.value, 10));
  };

  const handleChangeArea = event => {
    setAreaId(parseInt(event.target.value, 10));
  };

  const handleChangeTipo = event => {
    setTipoId(parseInt(event.target.value, 10));
  };

  const [setores, setSetores] = useState([]);

  const handleChangeSetorAtendimento = event => {
    setSetorAtendimentoId(event.target.value);
  };

  useEffect(() => {
    const fetchSetores = async () => {
      const params = {
        defensoria: setorOrigem.defensoria,
        tipo: TIPOS_SETORES.GABINETE_DEFENSOR,
      };

      const data = await getSetores(params);
      setSetores(data);
    };

    if (setorOrigem?.defensoria != null && setorOrigem?.defensoria !== '') {
      fetchSetores();
    }
  }, [setorOrigem]);

  const [setorAtendimentoIsDisabled, setSetorAtendimentoIsDisabled] =
    useState(false);

  useEffect(() => {
    if (setorOrigem != null) {
      setSetorAtendimentoIsDisabled(
        setorOrigem.tipo === TIPOS_SETORES.GABINETE_DEFENSOR,
      );
    }
  }, [setorOrigem]);

  const handleChangeProcesso = event => setProcesso(event.target.value);

  const [defensor, setDefensor] = useState('');
  const handleChangeDefensor = event => setDefensor(event.target.value);

  const [defensores, setDefensores] = useState([]);
  useEffect(() => {
    const fetchDefensores = async () => {
      const data = await getDefensores();
      setDefensores(data);
    };

    fetchDefensores();
  }, []);

  const handleChangeCpf = event => {
    setPessoa({ ...pessoa, cpf_cnpj: event.target.value });
  };

  const handleChangeNome = event => {
    setPessoa({ ...pessoa, nome: event.target.value });
  };

  const handleChangeTelefone = (event, field, id) => {
    const telefone = pessoa.telefones.find(el => el.id === id);
    telefone[field] = event.target.value;

    setPessoa({
      ...pessoa,
      telefones: pessoa.telefones.map(el => (el.id === id ? telefone : el)),
    });
  };

  const handleChangeEmail = (event, field, id) => {
    const email = pessoa.emails.find(el => el.id === id);
    email[field] = event.target.value;

    setPessoa({
      ...pessoa,
      emails: pessoa.emails.map(el => (el.id === id ? email : el)),
    });
  };

  const [historico, setHistorico] = useState();

  const handleHtmlContentChange = newContent => {
    setHistorico(newContent);
  };

  const [formIsDisabled, setFormIsDisabled] = useState(false);

  const tiposArquivo = useFetchTiposArquivos(defensoriaId);

  const [documentos, setDocumentos] = useState([]);
  const [tiposDocumento, setTiposDocumento] = useState([]);

  const TIPO_DOCUMENTO_PADRAO = '5';

  useEffect(() => {
    const fetchTiposDocumento = async () => {
      const data = await getTiposDocumento();
      setTiposDocumento(data);
    };

    fetchTiposDocumento();
  }, []);

  const onDropDocuments = useCallback(
    acceptedFiles => {
      const newDocumentos = acceptedFiles.map(f => ({
        tipo: TIPO_DOCUMENTO_PADRAO,
        file: f,
      }));

      setDocumentos([...documentos, ...newDocumentos]);
    },
    [documentos],
  );

  const onRemoveDocumento = (documento, index) => {
    setDocumentos(documentos.filter(d => d.file.name !== documento.file.name));

    setError({
      ...error,
      data: {
        arquivo: error.data.arquivo.filter(
          (_, arquivoIndex) => arquivoIndex !== index,
        ),
      },
    });
  };

  const handleChangeTipoDocumento = (event, documento) => {
    setDocumentos(
      documentos.map(d => {
        return d.file.name === documento.file.name
          ? { ...d, tipo: event.target.value }
          : d;
      }),
    );
  };

  useEffect(() => {
    if (documentos.length) {
      setFormIsDisabled(!documentos.every(d => d.tipo !== ''));
    }
  }, [documentos]);

  async function uploadDocumentosToServer(eventoId) {
    for (const documento of documentos) {
      const formData = new FormData();

      formData.append('nome', documento.file.name);
      formData.append('arquivo', documento.file, documento.file.name);
      formData.append('tipo', parseInt(documento.tipo, 10));
      formData.append('evento', parseInt(eventoId, 10));

      // eslint-disable-next-line no-await-in-loop
      await uploadDocument(formData);
    }
  }

  const tiposTelefone = useSelector(selectAllTiposTelefone);
  const tiposTelefoneStatus = useSelector(state => state.tiposTelefone.status);

  useEffect(() => {
    if (tiposTelefoneStatus === 'idle') {
      dispatch(fetchTiposTelefone());
    }
  }, [dispatch, tiposTelefoneStatus]);

  const tiposEmail = useSelector(selectAllTiposEmail);
  const tiposEmailStatus = useSelector(state => state.tiposEmail.status);

  useEffect(() => {
    if (tiposEmailStatus === 'idle') {
      dispatch(fetchTiposEmail());
    }
  }, [dispatch, tiposEmailStatus]);

  const tiposSolicitacao = useSelector(selectAllTiposSolicitacao);
  const tiposSolicitacaoStatus = useSelector(
    state => state.tiposSolicitacao.status,
  );

  useEffect(() => {
    if (tiposSolicitacaoStatus === 'idle') {
      dispatch(fetchTiposSolicitacao());
    } else if (tiposSolicitacaoStatus === 'succeeded') {
      setTipoId(tiposSolicitacao[0].id);
    }
  }, [dispatch, tiposSolicitacao, tiposSolicitacaoStatus]);

  const handleAddTelefone = () => {
    setPessoa({
      ...pessoa,
      telefones: [
        ...pessoa.telefones,
        {
          id: new Date().valueOf(),
          numero: '',
          tipo: 1,
          complemento: '',
        },
      ],
    });
  };

  const handleAddEmail = () => {
    setPessoa({
      ...pessoa,
      emails: [
        ...pessoa.emails,
        {
          id: new Date().valueOf(),
          email: '',
          tipo: 1,
          complemento: '',
        },
      ],
    });
  };

  const handleRemoveTelefone = id => {
    setPessoa({
      ...pessoa,
      telefones: pessoa.telefones.filter(el => el.id !== id),
    });
  };

  const handleRemoveEmail = id => {
    setPessoa({
      ...pessoa,
      emails: pessoa.emails.filter(el => el.id !== id),
    });
  };

  const [error, setError] = useState({ data: null, message: '' });

  let eventos = [];

  const cadastradoPor = Number.isNaN(parseInt(defensor, 10))
    ? auth.user.id
    : parseInt(defensor, 10);

  if (setorAtendimentoId != null && setorAtendimentoId !== '') {
    eventos = [
      {
        setor_criacao: parseInt(setorAtendimentoId, 10),
        setor_encaminhado: parseInt(setorAtual, 10),
        cadastrado_por: cadastradoPor,
        documentos: [],
        tipo: 1,
      },
    ];

    if (setorAtendimentoIsDisabled) {
      delete eventos[0].setor_encaminhado;
    }
  } else {
    eventos = [
      {
        setor_criacao: parseInt(setorAtual, 10),
        setor_encaminhado: null,
        cadastrado_por: cadastradoPor,
        documentos: [],
        historico,
        tipo: 1,
      },
    ];
  }

  const payload = {
    defensoria_origem_id: setorOrigem?.defensoria, // id da defensoria do setor na sessão do usuário
    defensoria_destino_id: defensoriaId, // id da defensoria p/ encaminhamento
    localidade: localidade?.id, // id da localidade p/ encaminhamento
    area: areaId, // id da área p/ encaminhamento
    tipo: tipoId, // id do tipo de solicitação
    eh_urgente: urgente,
    processo: {
      // opcional: número do processo judicial
      numero: processo,
    },
    eventos,
    pessoa,
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (formIsDisabled) {
      return;
    }

    setOverlayIsOpen(true);
    setFormIsDisabled(true);

    const dataPayload = copy(payload);

    try {
      if (dataPayload.processo.numero == null) {
        delete dataPayload.processo;
      }

      if (dataPayload.pessoa.nome.trim().length === 0) {
        delete dataPayload.pessoa;
      }

      if ('pessoa' in dataPayload) {
        dataPayload.pessoa.telefones = dataPayload.pessoa.telefones.filter(
          telefone => telefone.numero.trim() !== '',
        );
        dataPayload.pessoa.emails = dataPayload.pessoa.emails.filter(
          email => email.email.trim() !== '',
        );
      }

      Sentry.setContext('POST_PAYLOAD', payload);
      Sentry.setContext('POST_PAYLOAD_TRANSFORMED', dataPayload);

      const { data: responseData, statusText } = await salvarSolicitacao(
        dataPayload,
      );

      if (statusText === 'Created') {
        const { id } = responseData;

        const response = await getSolicitacaoPorId(id);
        const eventoId = response.eventos[0].id;

        await uploadDocumentosToServer(eventoId);

        setError({ data: null, message: '' });
        setOverlayIsOpen(false);

        toast({
          title: 'Salvar solicitação',
          description: 'Nova solicitação salva com sucesso',
          status: 'success',
          duration: 2500,
          isClosable: true,
          onCloseComplete: () => navigate(`/solicitacoes/${id}`),
        });
      }
    } catch (ex) {
      Sentry.setContext('POST_PAYLOAD', payload);
      Sentry.setContext('POST_PAYLOAD_TRANSFORMED', dataPayload);
      Sentry.captureException(ex);

      setFormIsDisabled(false);

      let errorMessage = '';

      if (ex.isAxiosError && ex.response.status < 500) {
        errorMessage =
          'Verifique no formulário os campos que apresentaram erros.';
        setError({ data: ex.response.data, message: errorMessage });
      } else {
        errorMessage = `Entre em contato com o suporte técnico para resolver esse problema. Descrição do problema: ${ex}`;
        setError({ message: errorMessage });
      }

      toast({
        title: 'Erro ao salvar solicitação',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => setOverlayIsOpen(false),
      });
    }
  };

  const [overlayIsOpen, setOverlayIsOpen] = useState(false);

  return (
    <>
      <OverlaySpinner isOpen={overlayIsOpen} />

      <Container maxW="container.xl" p="2">
        <form onSubmit={handleSubmit}>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb="3"
            sx={{ gap: '0.5rem' }}
          >
            <Heading as="h2" size="lg">
              Nova Solicitação
            </Heading>

            <Box>
              <Button
                as={RouterLink}
                to="/acompanhamento"
                mr="1"
                colorScheme="blackAlpha"
                leftIcon={<Icon as={FaArrowAltCircleLeft} />}
              >
                Voltar
              </Button>
            </Box>
          </Flex>

          {isNil(error?.data) && error?.message && (
            <Box
              my="3"
              // bgColor="red.500"
              // color="white"
              maxW={{ md: 'container.md' }}
              fontWeight="bold"
              border="1px solid"
              borderColor="red.400"
              display="flex"
              p="0"
            >
              <Box bgColor="red.400" color="white" p="3">
                <Icon as={FaExclamationCircle} fontSize="2xl" />
              </Box>

              <Box p="3">{error?.message}</Box>
            </Box>
          )}

          <Box>
            <Box mb="3">
              <FormLabel>
                Encaminhar para:{' '}
                <Text as="span" color="red">
                  *
                </Text>
              </FormLabel>

              <Flex sx={{ gap: '.5rem' }}>
                <FormControl isRequired>
                  <Select
                    value={defensoriaId}
                    onChange={handleChangeDefensoria}
                  >
                    {defensorias.map(defensoria => (
                      <option key={defensoria.id} value={defensoria.id}>
                        {defensoria.nome}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl isRequired>
                  <Select
                    value={localidadeId}
                    onChange={handleChangeLocalidade}
                  >
                    {localidades.map(localidadeEl => (
                      <option key={localidadeEl.id} value={localidadeEl.id}>
                        {localidadeEl.nome}
                      </option>
                    ))}
                  </Select>

                  {localidade && !localidade?.eh_atendida_pela_defensoria && (
                    <Text>
                      <Icon
                        as={FaExclamationCircle}
                        color="orange.400"
                        me="1"
                      />
                      <Text verticalAlign="middle" as="span">
                        Esta localidade não é atendida pela Defensoria
                      </Text>
                    </Text>
                  )}

                  <FormErrorMessage>
                    O campo localidade é obrigatório
                  </FormErrorMessage>
                </FormControl>

                <FormControl isRequired>
                  <Select value={areaId} onChange={handleChangeArea}>
                    {areas.map(area => (
                      <option key={area.id} value={area.id}>
                        {area.nome}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
            </Box>

            <Box mb="3">
              <FormControl isRequired>
                <FormLabel>Tipo:</FormLabel>

                <Select value={tipoId} onChange={handleChangeTipo}>
                  {tiposSolicitacao.map(tipoSolicitacao => (
                    <option key={tipoSolicitacao.id} value={tipoSolicitacao.id}>
                      {tipoSolicitacao.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Flex mb="3">
              <Box mr="10">
                <FormLabel>Urgente?</FormLabel>

                <Box>
                  <Switch
                    isChecked={urgente}
                    onChange={setUrgente.toggle}
                    size="lg"
                    mt="1"
                    colorScheme="red"
                  />
                </Box>
              </Box>

              <FormControl flex="1" isInvalid={!!error?.data?.processo?.numero}>
                <FormLabel>Número do processo judicial</FormLabel>

                <Input
                  placeholder="123456789"
                  onChange={handleChangeProcesso}
                />

                <FormErrorMessage>
                  O número do processo judicial tem mais de 50 caracteres
                </FormErrorMessage>

                <FormHelperText>Máximo de 50 caracteres</FormHelperText>
              </FormControl>
            </Flex>

            <HStack mb="3" spacing="1">
              <FormControl isDisabled={setorAtendimentoIsDisabled}>
                <FormLabel>Defensor(a) que atendeu o Assistido:</FormLabel>

                <Select value={defensor} onChange={handleChangeDefensor}>
                  <option value="">Nenhum(a)</option>
                  {defensores.map(defensorEl => (
                    <option key={defensorEl.id} value={defensorEl.id}>
                      {defensorEl.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isDisabled={setorAtendimentoIsDisabled}>
                <FormLabel>Defensoria que atendeu o Assistido:</FormLabel>

                <Select
                  value={setorAtendimentoId}
                  onChange={handleChangeSetorAtendimento}
                >
                  <option value="">Nenhum</option>
                  {setores.map(setor => (
                    <option key={setor.id} value={setor.id}>
                      {setor.nome}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </HStack>

            <Box mb="3">
              <FormLabel color={isNil(error?.data?.pessoa) ? '' : 'red.500'}>
                Dados da Pessoa Assistida
                {!isNil(error?.data?.pessoa) && ' *'}
              </FormLabel>

              <Flex mb="1.5" sx={{ gap: '0.5rem' }}>
                <FormControl
                  flex="5"
                  isInvalid={!isNil(error?.data?.pessoa?.cpf_cnpj)}
                >
                  <Input
                    placeholder="Digite seu CPF"
                    onChange={handleChangeCpf}
                    as={Cleave}
                    options={{
                      blocks: [3, 3, 3, 2],
                      delimiters: ['.', '.', '-'],
                      numericOnly: true,
                    }}
                  />
                  <FormErrorMessage>
                    {error?.data?.pessoa?.cpf_cnpj?.join(' ')}
                  </FormErrorMessage>
                </FormControl>

                <Input
                  placeholder="Nome: Ex.: Maria da Silva"
                  flex="11"
                  onChange={handleChangeNome}
                />
              </Flex>

              {pessoa.telefones.map((telefone, index) => (
                <Flex key={telefone.id} mb="1.5">
                  <Flex sx={{ gap: '.5rem' }} minW="container.md">
                    <FormControl
                      isInvalid={!isNil(error?.data?.pessoa?.telefones)}
                    >
                      <Input
                        placeholder="(63) 99999-9999"
                        onChange={event =>
                          handleChangeTelefone(event, 'numero', telefone.id)
                        }
                        value={telefone.numero}
                      />

                      <FormErrorMessage>
                        {error?.data?.pessoa?.telefones?.[index]?.numero?.join(
                          ' ',
                        )}
                      </FormErrorMessage>
                    </FormControl>

                    <Select
                      onChange={event =>
                        handleChangeTelefone(event, 'tipo', telefone.id)
                      }
                    >
                      {tiposTelefone.map(tipoTelefone => (
                        <option key={tipoTelefone.id} value={tipoTelefone.id}>
                          {tipoTelefone.nome}
                        </option>
                      ))}
                    </Select>

                    <Input
                      placeholder="Obs.: Telefone da vizinha"
                      onChange={event =>
                        handleChangeTelefone(event, 'complemento', telefone.id)
                      }
                    />
                  </Flex>

                  {pessoa.telefones.length === index + 1 ? (
                    <IconButton
                      aria-label="Acrescentar telefone"
                      ml="2"
                      icon={<FaPlus />}
                      onClick={handleAddTelefone}
                    />
                  ) : (
                    <IconButton
                      aria-label="Remover telefone"
                      ml="2"
                      icon={<FaMinus />}
                      onClick={() => handleRemoveTelefone(telefone.id)}
                    />
                  )}
                </Flex>
              ))}

              {pessoa.emails.map((email, index) => (
                <Flex key={email.id} mb="1.5">
                  <Flex sx={{ gap: '.5rem' }} minW="container.md">
                    <FormControl
                      isInvalid={!isNil(error?.data?.pessoa?.emails)}
                    >
                      <Input
                        placeholder="mail@mail.com"
                        onChange={event =>
                          handleChangeEmail(event, 'email', email.id)
                        }
                      />

                      <FormErrorMessage>
                        {error?.data?.pessoa?.emails?.[index]?.email?.join(' ')}
                      </FormErrorMessage>
                    </FormControl>

                    <Select
                      onChange={event =>
                        handleChangeEmail(event, 'tipo', email.id)
                      }
                    >
                      {tiposEmail.map(tipoEmail => (
                        <option key={tipoEmail.id} value={tipoEmail.id}>
                          {tipoEmail.nome}
                        </option>
                      ))}
                    </Select>

                    <Input
                      placeholder="Obs.:"
                      onChange={event =>
                        handleChangeEmail(event, 'complemento', email.id)
                      }
                    />
                  </Flex>

                  {pessoa.emails.length === index + 1 ? (
                    <IconButton
                      aria-label="Acrescentar email"
                      ml="2"
                      icon={<FaPlus />}
                      onClick={handleAddEmail}
                    />
                  ) : (
                    <IconButton
                      aria-label="Remover email"
                      ml="2"
                      icon={<FaMinus />}
                      onClick={() => handleRemoveEmail(email.id)}
                    />
                  )}
                </Flex>
              ))}
            </Box>

            <Box mb="3">
              <FormLabel>Anotações</FormLabel>

              <HtmlEditor onContentChange={handleHtmlContentChange} />
            </Box>

            <UploadDocumentos
              onDropDocuments={onDropDocuments}
              documentos={documentos}
              isDisabled={formIsDisabled}
              restrictions={tiposArquivo}
            />

            {documentos.length > 0 && (
              <Box
                border="1px solid"
                borderColor="gray.300"
                borderRadius="lg"
                p="5"
                mb="3"
              >
                {documentos.map((documento, index) => (
                  <Flex
                    key={`${documento.file.name}-${documento.file.lastModified}`}
                    alignItems={documento.tipo === '' ? 'flex-start' : 'center'}
                    mb="3"
                  >
                    <Flex alignItems="center" flex="1" mr="3">
                      <Icon
                        as={getFileIcon(documento.file.name)}
                        color="red.500"
                        me="2"
                        fontSize="xl"
                      />

                      <Box
                        w="full"
                        textColor={
                          isNil(error?.data?.arquivo[index]) ? '' : 'red.500'
                        }
                      >
                        <Text>{documento.file.name}</Text>
                        {error?.data?.arquivo[index] && (
                          <Text>
                            <Icon as={FaExclamationCircle} me="1" />
                            <chakra.span verticalAlign="middle">
                              {error?.data?.arquivo[index]}
                            </chakra.span>
                          </Text>
                        )}
                      </Box>

                      <FormControl>
                        <Select
                          onChange={event =>
                            handleChangeTipoDocumento(event, documento)
                          }
                          value={documento.tipo}
                        >
                          {tiposDocumento.map(tipo => (
                            <option key={tipo.id} value={tipo.id}>
                              {tipo.nome}
                            </option>
                          ))}
                        </Select>

                        {documento.tipo === '' && (
                          <FormHelperText color="red">
                            O tipo do documento é obrigatório
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Flex>

                    <IconButton
                      icon={<Icon as={FaTimes} fontSize="sm" />}
                      onClick={() => onRemoveDocumento(documento, index)}
                    />
                  </Flex>
                ))}
              </Box>
            )}

            <HStack spacing="1" justifyContent="flex-end">
              <Button
                as={RouterLink}
                to="/acompanhamento"
                mr="1"
                colorScheme="blackAlpha"
                leftIcon={<Icon as={FaTimes} />}
              >
                Cancelar
              </Button>

              <Button
                colorScheme="green"
                leftIcon={<Icon as={FaSave} />}
                type="submit"
                disabled={formIsDisabled}
              >
                Salvar
              </Button>
            </HStack>
          </Box>
        </form>
      </Container>
    </>
  );
}
