import { Flex, Icon, Text } from '@chakra-ui/react';
import { FaExclamationCircle } from 'react-icons/fa';

export default function TestEnvironment() {
  const environment =
    process.env.NODE_ENV === 'development' ? 'desenvolvimento' : 'treinamento';

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_APPLICATION_ENV === 'homolog'
  ) {
    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        py="2"
        px="1"
        border="1px solid"
        borderColor="red.500"
      >
        <Icon as={FaExclamationCircle} mr="2" color="red" fontSize="1.75rem" />
        <Text fontWeight="bold">
          Você está utilizando o ambiente de {environment}!
        </Text>
      </Flex>
    );
  }

  return null;
}
