import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Icon,
  IconButton,
  Progress,
  Tab,
  Table,
  TableCaption,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { FaExclamationCircle, FaEye } from 'react-icons/fa';

import { useAuth } from '../../context/ProviderAuth';
import { useSetorAtual } from '../../context/ProviderSetorAtual';
import { useGetSolicitacoesPorSituacaoQuery } from '../../services/sidApi';
import { pollingInterval } from '../../utils';
import { formatDateTime } from '../../utils/formatDate';

export default function AtividadesRecentes(props) {
  const { setorAtual } = useSetorAtual();
  const auth = useAuth();

  const { data: { results: atividadesFeitasPorMim = [] } = {}, isFetching } =
    useGetSolicitacoesPorSituacaoQuery(
      {
        cadastrado_por: auth.user.id,
        ordering: '-modificado_em',
        limit: 8,
      },
      {
        pollingInterval: pollingInterval(),
      },
    );

  const { data: { results: atividadesEnviadasParaMim = [] } = {} } =
    useGetSolicitacoesPorSituacaoQuery(
      {
        setor_encaminhado: setorAtual,
        ordering: '-modificado_em',
        limit: 8,
      },
      {
        pollingInterval: pollingInterval(),
        skip: setorAtual === null || setorAtual === '',
      },
    );

  const atividadesRecentes = {
    'Feitas por mim': atividadesFeitasPorMim,
    'Enviadas para mim': atividadesEnviadasParaMim,
  };

  return (
    <Box {...props}>
      <Text fontWeight="bold" fontSize="lg">
        Atividades Recentes
      </Text>

      <Tabs>
        <TabList>
          {Object.keys(atividadesRecentes).map(nome => (
            <Tab key={nome}>{nome}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {Object.entries(atividadesRecentes).map(([nome, atividades]) => (
            <TabPanel key={nome}>
              <Progress size="xs" isIndeterminate={isFetching} />

              <Box
                border="1px solid"
                borderColor="gray.300"
                p="3"
                overflowX="auto"
              >
                <Table>
                  <TableCaption>{nome}</TableCaption>

                  <Thead>
                    <Tr>
                      <Th>Nº</Th>
                      <Th>
                        {nome === 'Feitas por mim' ? 'Destino' : 'Origem'}
                      </Th>
                      <Th>Pessoa Assistida</Th>
                      <Th>Ações</Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {atividades.map(atividade => (
                      <Tr key={atividade.id}>
                        <Td>
                          <Tag mb="1">{atividade.solicitacao?.numero}</Tag>

                          <Box>
                            {atividade.solicitacao.eh_urgente && (
                              <Tag mb="1" colorScheme="red">
                                <TagLeftIcon as={FaExclamationCircle} />
                                <TagLabel>Urgente</TagLabel>
                              </Tag>
                            )}
                          </Box>

                          <small>
                            {formatDateTime(atividade.modificado_em, true)}
                          </small>
                        </Td>
                        <Td>
                          {nome === 'Feitas por mim'
                            ? atividade.setor_encaminhado?.nome
                            : atividade.setor_criacao?.nome}
                        </Td>
                        <Td>{atividade.solicitacao?.pessoa?.nome}</Td>
                        <Td>
                          <RouterLink
                            to={`/solicitacoes/${atividade.solicitacao.id}`}
                          >
                            <IconButton
                              icon={<Icon as={FaEye} />}
                              title="Visualizar"
                            />
                          </RouterLink>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
