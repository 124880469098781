import { createRef, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Circle,
  Container,
  Divider,
  Flex,
  HStack,
  Icon,
  IconButton,
  Img,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Select,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';

import {
  FaBell,
  FaEnvelope,
  FaSearch,
  FaSignOutAlt,
  FaTools,
  FaUser,
} from 'react-icons/fa';

import * as Sentry from '@sentry/react';
import { useDispatch } from 'react-redux';
import { getSetoresPorResponsavel, readNotificacao } from '../services/api';
import { sidApi, useGetNotificacoesQuery } from '../services/sidApi';
import { pollingInterval } from '../utils';

import { useAuth } from '../context/ProviderAuth';
import { useSetorAtual } from '../context/ProviderSetorAtual';
import TestEnvironment from './TestEnvironment';

export default function Navbar() {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { setorAtual, changeSetorAtual } = useSetorAtual();

  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const handleSearchTextChange = event => setSearchText(event.target.value);
  const handleSearchTextOnEnter = () => {
    navigate(`/solicitacoes/busca?texto=${searchText}`);
  };

  useEffect(() => {
    if (!location.pathname.startsWith('/solicitacoes/busca')) {
      setSearchText('');
    }
  }, [location]);

  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const ref = createRef();

  useOutsideClick({
    ref,
    handler: () => setShowUserDropdown(false),
  });

  let ADMIN_URL = `${process.env.REACT_APP_BACKEND_API_URL}/admin`;
  ADMIN_URL = ADMIN_URL.replace('/api', '');

  const [setores, setSetores] = useState([]);

  const handleChangeSetorAtual = event => changeSetorAtual(event.target.value);

  useEffect(() => {
    const fetchSetores = async () => {
      try {
        const data = await getSetoresPorResponsavel(auth.user.id);
        setSetores(data);
      } catch (ex) {
        Sentry.captureException(ex);
      }
    };

    fetchSetores();
  }, [auth.user.id]);

  const { data: notificacoes = [] } = useGetNotificacoesQuery(setorAtual, {
    skip: setorAtual === null || setorAtual === '',
    pollingInterval: pollingInterval(),
  });

  const { isOpen: showNotificacoes, onToggle: toggleShowNotificacoes } =
    useDisclosure();

  const handleNotificacaoClick = async (event, notificacao) => {
    event.preventDefault();

    await readNotificacao(notificacao.id);

    dispatch(sidApi.util.invalidateTags(['Notificacao']));

    toggleShowNotificacoes();

    navigate(`/solicitacoes/${notificacao.evento.solicitacao}`);
  };

  const handleLogout = () => {
    auth.signout();
    navigate('/');
  };

  return (
    <>
      <Box shadow="md" bgColor="green.500">
        <Container maxW="container.xl" px="2">
          <Stack direction={['column', null, 'row']} alignItems="center" py="2">
            <Link
              as={RouterLink}
              to="/acompanhamento"
              bgColor="transparent"
              onClick={() => navigate('/acompanhamento')}
              transition="background-color .450s"
              _hover={{ bgColor: 'green.400' }}
              _focus={{ boxShadow: 'none' }}
              borderRadius={5}
              p="2.5"
            >
              <Img src="/sid-logo.png" width="60px" height="auto" />
            </Link>

            <InputGroup role="group">
              <Input
                placeholder="Digite algo e pressione ENTER para buscar por solicitações"
                bgColor="white"
                value={searchText}
                onChange={event => handleSearchTextChange(event)}
                onKeyDown={event => {
                  const { key, code } = event;

                  if (key !== undefined || code !== undefined) {
                    if (
                      key.toLowerCase() === 'enter' ||
                      code.toLowerCase() === 'enter'
                    ) {
                      handleSearchTextOnEnter();
                    }
                  }
                }}
              />
              <InputRightElement>
                <Icon
                  as={FaSearch}
                  color="gray.400"
                  _groupHover={{ color: 'gray.500' }}
                  transitionDuration=".3s"
                />
              </InputRightElement>
            </InputGroup>

            <Select
              onChange={handleChangeSetorAtual}
              bgColor="white"
              maxW="max-content"
            >
              {setores.length === 0 ? (
                <option>Usuário não vinculado a nenhum setor</option>
              ) : (
                setores.map(setor => (
                  <option key={setor.id} value={setor.id}>
                    {setor.nome}
                  </option>
                ))
              )}
            </Select>

            <Flex
              alignItems="center"
              justifyContent="flex-end"
              w={['full', 'full', 'min-content']}
              sx={{ gap: '.75rem' }}
            >
              <Popover placement="bottom-end" isOpen={showNotificacoes}>
                <PopoverTrigger>
                  <Box pos="relative">
                    <IconButton
                      onClick={toggleShowNotificacoes}
                      icon={<Icon as={FaBell} />}
                    />

                    {notificacoes.length > 0 && (
                      <Circle
                        size="20px"
                        bg="red"
                        color="white"
                        pos="absolute"
                        top="0"
                        right="0"
                        transform="translate(20%, -30%)"
                      >
                        <Text as="span" fontSize="x-small" fontWeight="bold">
                          {notificacoes.length}
                        </Text>
                      </Circle>
                    )}
                  </Box>
                </PopoverTrigger>

                <PopoverContent
                  w={[
                    '18.5rem',
                    '30rem',
                    notificacoes.length === 0 ? 'max-content' : '35rem',
                  ]}
                  maxH="min(75vh, 40rem)"
                  overflow="auto"
                >
                  <PopoverBody>
                    {notificacoes.length ? (
                      notificacoes.map(notificacao => (
                        <Link
                          display="block"
                          href={`/notificacoes/${notificacao.id}`}
                          role="button"
                          variant="link"
                          key={notificacao.id}
                          _notLast={{
                            borderBottom: '1px solid',
                            borderBottomColor: 'gray.300',
                          }}
                          py="3"
                          px="2"
                          onClick={event =>
                            handleNotificacaoClick(event, notificacao)
                          }
                          cursor="pointer"
                          _hover={{
                            textDecoration: 'none',
                            bgColor: 'gray.50',
                          }}
                        >
                          <Text fontWeight="bold" mb="1">
                            {notificacao.titulo}
                          </Text>

                          <Text>{notificacao.historico}</Text>
                        </Link>
                      ))
                    ) : (
                      <Text textAlign="center">
                        Nenhuma notificação para exibir
                      </Text>
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Popover>

              {auth.user.is_staff && (
                <Link
                  href={ADMIN_URL}
                  target="_blank"
                  bgColor="red.400"
                  color="white"
                  px="4"
                  py="2"
                  borderRadius="md"
                  _hover={{ bgColor: 'red.500' }}
                  _focus={{
                    shadow: 'rgba(245, 101, 101, 0.6) 0px 0px 0px 3px',
                  }}
                  display="flex"
                  alignItems="center"
                >
                  <Icon as={FaTools} mr="2" />
                  Admin
                </Link>
              )}

              <div ref={ref}>
                <Popover placement="bottom-end" isOpen={showUserDropdown}>
                  <PopoverTrigger>
                    <Avatar
                      cursor="pointer"
                      onClick={() => setShowUserDropdown(!showUserDropdown)}
                    />
                  </PopoverTrigger>

                  <PopoverContent w="max-content">
                    <PopoverBody>
                      <Box bg="white">
                        <Flex alignItems="center" mb="3">
                          <Icon as={FaUser} mr="1.5" />
                          <Text userSelect="none">{auth.user.nome}</Text>
                        </Flex>

                        <Flex alignItems="center" mb="3">
                          <Icon as={FaEnvelope} mr="1.5" />
                          <Text userSelect="none">{auth.user.email}</Text>
                        </Flex>

                        <Divider h="0.5" bg="gray.400" mb="3" />

                        <HStack alignItems="center">
                          <Link
                            w="full"
                            to="/alterar-senha"
                            as={RouterLink}
                            textAlign="center"
                          >
                            Alterar senha
                          </Link>

                          <Button
                            w="full"
                            leftIcon={<Icon as={FaSignOutAlt} />}
                            onClick={handleLogout}
                            bg={useColorModeValue('gray.200', 'gray.300')}
                            color={useColorModeValue('inherit', 'gray.800')}
                            _hover={{
                              bg: useColorModeValue('gray.300', 'gray.400'),
                            }}
                          >
                            Sair
                          </Button>
                        </HStack>
                      </Box>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </div>
            </Flex>
          </Stack>
        </Container>
      </Box>

      <TestEnvironment />
    </>
  );
}
