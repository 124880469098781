import { useState } from 'react';

import {
  Box,
  Button,
  chakra,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';

import * as Sentry from '@sentry/react';

import { FaExclamationCircle } from 'react-icons/fa';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import TestEnvironment from '../../components/TestEnvironment';
import { resetPassword } from '../../services/api';

import UnauthenticatedNavbar from './UnauthenticatedNavbar';

const MessageKeyMap = {
  old_password: 'Senha atual',
  password: 'Nova senha',
  password_confirmation: 'Confirmar senha',
  detail: 'Erro',
};

export default function AlterarSenha() {
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const token = query.get('token');

  const [novaSenha, setNovaSenha] = useState('');
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('');

  const handleChangeNovaSenha = ({ target: { value } }) => setNovaSenha(value);
  const handleChangeConfirmacaoSenha = ({ target: { value } }) =>
    setConfirmacaoSenha(value);

  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleResetarSenha = async () => {
    if (novaSenha !== confirmacaoSenha) {
      setError('Nova senha e confirmação de senha devem ser iguais!');
      return;
    }

    try {
      setIsLoading(true);

      await resetPassword(token, novaSenha);

      setSuccess(true);
      setError(null);
    } catch (ex) {
      Sentry.captureException(ex);

      setError(ex.response?.data ?? ex.message);

      setSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <UnauthenticatedNavbar />
      <TestEnvironment />
      <Container maxW="container.xl" p="6">
        <Box
          maxW="96"
          mx="auto"
          shadow="md"
          p="10"
          borderTop="1px solid"
          borderColor="gray.100"
        >
          <Heading as="h2" fontSize="1.75rem" mb="8" textAlign="center">
            Recuperar Senha
          </Heading>

          <FormControl mb="3">
            <FormLabel>Nova senha</FormLabel>
            <Input
              value={novaSenha}
              onChange={handleChangeNovaSenha}
              onKeyDown={event =>
                event.code === 'Enter' ? handleResetarSenha() : null
              }
              type="password"
            />
          </FormControl>

          <FormControl mb="3">
            <FormLabel>Confirmar senha</FormLabel>
            <Input
              value={confirmacaoSenha}
              onChange={handleChangeConfirmacaoSenha}
              onKeyDown={event =>
                event.code === 'Enter' ? handleResetarSenha() : null
              }
              type="password"
            />
          </FormControl>

          <Button
            onClick={handleResetarSenha}
            colorScheme="green"
            w="full"
            mb="3"
            isLoading={isLoading}
          >
            RECUPERAR SENHA
          </Button>
        </Box>

        {success && (
          <Box
            py="3"
            px="4"
            borderRadius="md"
            maxW="container.sm"
            mx="auto"
            mt="6"
            textAlign="center"
          >
            <Text fontWeight="bold" fontSize="1.5rem">
              Senha recuperada com sucesso!
            </Text>
            <Text mb="3" color="gray.600">
              Você já pode acessar o sistema com a sua nova senha
            </Text>

            <Link
              as={RouterLink}
              to="/login"
              color="green.700"
              fontSize="1.05rem"
            >
              Ir para a tela de login
            </Link>
          </Box>
        )}

        {error && (
          <Box
            maxW="container.sm"
            mx="auto"
            mt="6"
            border="1px solid"
            borderColor="gray.600"
            borderRadius="md"
            borderLeft="6px solid"
            borderLeftColor="red"
            py="3"
            px="5"
          >
            {typeof error === 'object' ? (
              <>
                <Text mb="3">
                  Ocorreu algum erro ao tentar resetar a sua senha. Verifique o
                  seguinte:
                </Text>

                <chakra.ul>
                  {Object.entries(error).map(([key, messages]) => (
                    <chakra.li key={key} listStyleType="none">
                      - {MessageKeyMap[key]}:{' '}
                      {Array.isArray(messages) ? messages.join('. ') : messages}
                    </chakra.li>
                  ))}
                </chakra.ul>
              </>
            ) : (
              <Text textAlign="center" color="red">
                <Icon as={FaExclamationCircle} me="2" />
                <Text as="span" verticalAlign="middle">
                  {error}
                </Text>
              </Text>
            )}
          </Box>
        )}
      </Container>
    </>
  );
}
