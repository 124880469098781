import { Link as RouterLink } from 'react-router-dom';
import { Flex, Img, Link, Text } from '@chakra-ui/react';

export default function UnauthenticatedNavbar() {
  return (
    <Flex bg="green.500" h="16" py="3" px="4" alignItems="center">
      <Link as={RouterLink} to="/login">
        <Img src="/sid-logo.png" width="50px" height="auto" me="2" />
      </Link>

      <Text
        color="white"
        fontWeight="bold"
        fontSize="1.25rem"
        mt="0.5"
        letterSpacing="wide"
      >
        Sistema de Peticionamento Integrado
      </Text>
    </Flex>
  );
}
