import { useEffect, useState, useCallback } from 'react';

import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Spinner,
  Table,
  Tag,
  TagLabel,
  TagLeftIcon,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { FaExclamationCircle, FaEye, FaTimes } from 'react-icons/fa';

import * as Sentry from '@sentry/react';

import { getSolicitacoesPaginada } from '../../services/api';
import { deleteKeyFromObject } from '../../utils';
import { formatDateTime } from '../../utils/formatDate';

import Pagination from '../../components/Pagination';
import useFetchDefensorias from '../../hooks/useFetchDefensorias';
import useFetchSituacoesSolicitacao from '../../hooks/useFetchSituacoesSolicitacao';

const SolicitacaoSituacoesTagMap = {
  1: {
    nome: 'Rascunho',
    colorScheme: 'gray',
  },

  2: {
    nome: 'Enviada',
    colorScheme: 'yellow',
  },

  3: {
    nome: 'Recebida',
    colorScheme: 'blue',
  },

  4: {
    nome: 'Protocolada',
    colorScheme: 'green',
  },

  5: {
    nome: 'Recusada',
    colorScheme: 'red',
  },
};

export default function SolicitacoesBusca() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [urgente, setUrgente] = useState('');
  const handleUrgenteChange = event => setUrgente(event.target.value);

  const [situacaoSolicitacao, setSituacaoSolicitacao] = useState('');
  const situacoesSolicitacao = useFetchSituacoesSolicitacao();
  const handleSituacaoSolicitacaoChange = event =>
    setSituacaoSolicitacao(event.target.value);

  const [defensoria, setDefensoria] = useState('');
  const defensorias = useFetchDefensorias();
  const handleDefensoriaChange = event => setDefensoria(event.target.value);
  useEffect(() => {
    setDefensoria(defensorias[0]?.id);
  }, [defensorias]);

  const [filtro, setFiltro] = useState(() => {
    return searchParams.get('texto') || '';
  });

  const handleFiltroChange = event => setFiltro(event.target.value);

  const [defensoriaTipoFiltro, setDefensoriaTipoFiltro] = useState('');
  const handleDefensoriaTipoFiltroChange = event =>
    setDefensoriaTipoFiltro(event.target.value);

  const [defensoriasIsDisabled, setDefensoriasIsDisabled] = useState(true);
  useEffect(() => {
    setDefensoriasIsDisabled(defensoriaTipoFiltro === '');
  }, [defensoriaTipoFiltro]);

  const [isLoading, setIsLoading] = useState(false);
  const [resultados, setResultados] = useState([]);

  const PAGE_LIMIT = 7;
  const [totalRecords, setTotalRecords] = useState(0);
  const [initialPaginationKey, setInitialPaginationKey] = useState(0);

  async function realizarBuscaFn(texto = '', offset = 0) {
    try {
      setIsLoading(true);

      const defensoriaKey = {
        originadas_por: 'defensoria_origem',
        destinadas_por: 'defensoria_destino',
        '': 'defensoria',
      };

      let ehUrgente = false;

      if (urgente === 'true') {
        ehUrgente = true;
      } else if (urgente === 'false') {
        ehUrgente = false;
      } else {
        ehUrgente = '';
      }

      let payload = {
        eh_urgente: ehUrgente,
        situacao: situacaoSolicitacao,
        [defensoriaKey[defensoriaTipoFiltro]]:
          defensoriaTipoFiltro === '' ? '' : defensoria,
        filtro: texto === '' ? filtro : texto,
      };

      if (payload.eh_urgente === '') {
        payload = deleteKeyFromObject(payload, 'eh_urgente');
      }

      if (payload.defensoria === '') {
        payload = deleteKeyFromObject(payload, 'defensoria');
      }

      const params = {
        ...payload,
        ordering: 'cadastrado_em',
        limit: PAGE_LIMIT,
        offset,
      };

      const data = await getSolicitacoesPaginada(params);

      setTotalRecords(data.count);
      setResultados(data.results);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  }

  const realizarBusca = useCallback(realizarBuscaFn, [
    defensoria,
    defensoriaTipoFiltro,
    filtro,
    situacaoSolicitacao,
    urgente,
  ]);

  useEffect(() => {
    if (searchParams.has('texto')) {
      setFiltro(searchParams.get('texto'));
      realizarBusca(searchParams.get('texto'));
    }
  }, [realizarBusca, searchParams]);

  async function handleSubmit(event) {
    event.preventDefault();
    await realizarBusca('', 0);
    setInitialPaginationKey(Math.random());
  }

  return (
    <Container maxW="container.xl" py="6" px="2">
      <chakra.form
        onSubmit={handleSubmit}
        display="flex"
        sx={{ gap: '.5rem' }}
        mb="3"
      >
        <FormControl display="flex" alignItems="center" w="max-content">
          <FormControl id="urgente" w="max-content">
            <FormLabel>Urgente?</FormLabel>

            <Select
              name="urgente"
              value={urgente}
              onChange={event => handleUrgenteChange(event)}
            >
              <option value="">Todas</option>
              <option value>Sim</option>
              <option value={false}>Não</option>
            </Select>
          </FormControl>
        </FormControl>

        <FormControl id="situacao_solicitacao" w="max-content">
          <FormLabel>Situação</FormLabel>

          <Select
            name="situacao_solicitacao"
            w="max-content"
            value={situacaoSolicitacao}
            onChange={event => handleSituacaoSolicitacaoChange(event)}
          >
            <option value="">Todas</option>
            {situacoesSolicitacao.map(situacao => (
              <option key={situacao.id} value={situacao.id}>
                {situacao.nome}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl display="flex" flexDirection="column">
          <FormLabel>Defensoria</FormLabel>

          <Flex sx={{ gap: '0.5rem' }}>
            <Select
              maxW="max-content"
              value={defensoriaTipoFiltro}
              onChange={event => handleDefensoriaTipoFiltroChange(event)}
            >
              <option value="">Todas</option>
              <option value="originadas_por">Originadas por:</option>
              <option value="destinadas_por">Destinadas para:</option>
            </Select>

            <FormControl id="defensoria" isDisabled={defensoriasIsDisabled}>
              <Select
                name="defensoria"
                value={defensoria}
                onChange={event => handleDefensoriaChange(event)}
              >
                {defensorias.map(defensoriaEl => (
                  <option key={defensoriaEl.id} value={defensoriaEl.id}>
                    {defensoriaEl.nome}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Flex>
        </FormControl>

        <FormControl id="filtro">
          <FormLabel>
            Número da Solicitação ou Nome/CPF da Pessoa Assistida
          </FormLabel>

          <InputGroup>
            <Input
              name="filtro"
              placeholder="Ex.: Fulano de Tal"
              value={filtro}
              onChange={event => handleFiltroChange(event)}
            />
            <InputRightElement>
              <IconButton
                icon={<Icon as={FaTimes} />}
                size="sm"
                onClick={() => setFiltro('')}
              />
            </InputRightElement>
          </InputGroup>
        </FormControl>

        <Flex direction="column" justify="flex-end">
          <Button type="submit" colorScheme="green" flexShrink="0">
            Buscar
          </Button>
        </Flex>
      </chakra.form>

      <Table variant="striped" border="1px solid" borderColor="gray.100" mb="3">
        <Thead>
          <Tr>
            <Th>Nº</Th>
            <Th>Data</Th>
            <Th>Origem</Th>
            <Th>Destino</Th>
            <Th>Pessoa Assistida</Th>
            <Th>Situação</Th>
            <Th>Ações</Th>
          </Tr>
        </Thead>

        <Tbody>
          {isLoading ? (
            <Tr>
              <Td colSpan="8" textAlign="center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="green"
                  size="xl"
                />
              </Td>
            </Tr>
          ) : (
            resultados.map(solicitacao => (
              <Tr key={solicitacao.id}>
                <Td>
                  <div>{solicitacao.numero}</div>
                  {solicitacao.eh_urgente && (
                    <Tag borderRadius="full" colorScheme="red">
                      <TagLeftIcon as={FaExclamationCircle} />
                      <TagLabel>Urgente</TagLabel>
                    </Tag>
                  )}
                </Td>
                <Td>{formatDateTime(solicitacao.cadastrado_em)}</Td>
                <Td>{solicitacao.defensoria_origem?.nome}</Td>
                <Td>{solicitacao.defensoria_destino?.nome}</Td>
                <Td>{solicitacao.pessoa?.nome}</Td>

                <Td>
                  <Tag
                    colorScheme={
                      SolicitacaoSituacoesTagMap[solicitacao.situacao]
                        .colorScheme
                    }
                  >
                    {SolicitacaoSituacoesTagMap[solicitacao.situacao].nome}
                  </Tag>
                </Td>

                <Td>
                  <IconButton
                    icon={<Icon as={FaEye} />}
                    colorScheme="teal"
                    onClick={() => navigate(`/solicitacoes/${solicitacao.id}`)}
                  />
                </Td>
              </Tr>
            ))
          )}
        </Tbody>
      </Table>

      <Box mb="3">Total de registros: {totalRecords}</Box>

      <Pagination
        key={initialPaginationKey}
        totalRecords={totalRecords}
        pageNeighbours={2}
        pageLimit={PAGE_LIMIT}
        onPageChanged={data => {
          const { currentPage } = data;
          const offset = PAGE_LIMIT * (currentPage - 1);
          realizarBusca('', offset);
        }}
      />
    </Container>
  );
}
