import {
  Box,
  Button,
  chakra,
  Container,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  useToast,
  VStack,
} from '@chakra-ui/react';

import axios from 'axios';

import fileDownload from 'js-file-download';

import { useEffect, useState } from 'react';
import {
  FaArrowAltCircleLeft,
  FaBookOpen,
  FaCalendarPlus,
  FaDownload,
  FaExclamationCircle,
  FaPaperclip,
} from 'react-icons/fa';

import { useDispatch } from 'react-redux';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import InlineDateTime from '../../components/InlineDateTime';

import { useSetorAtual } from '../../context/ProviderSetorAtual';

import {
  downloadSolicitacaoAllFiles,
  getSituacoesSolicitacao,
  getSolicitacaoPorId,
  reabrirSolicitacao,
  readNotificacao,
} from '../../services/api';

import { sidApi } from '../../services/sidApi';
import SolicitacaoError from './SolicitacaoError';
import SolicitacaoLoading from './SolicitacaoLoading';

const SolicitacaoSituacaoColors = {
  1: {
    bgColor: 'gray.300',
    textColor: 'black',
  },
  2: {
    bgColor: 'yellow.300',
    textColor: 'gray.800',
  },
  3: {
    bgColor: 'blue.200',
    textColor: 'gray.800',
  },
  4: {
    bgColor: 'green.400',
    textColor: 'grey.800',
  },
  5: {
    bgColor: 'red.300',
    textColor: 'white',
  },
};

export default function Solicitacao() {
  const { id } = useParams();
  const location = useLocation();
  const { setorAtual } = useSetorAtual();
  const toast = useToast();
  const dispatch = useDispatch();

  const [situacoesSolicitacao, setSituacoesSolicitacao] = useState([]);

  useEffect(() => {
    const fetchSituacoesSolicitacao = async () => {
      const data = await getSituacoesSolicitacao();
      setSituacoesSolicitacao(data);
    };

    fetchSituacoesSolicitacao();
  }, []);

  const [solicitacao, setSolicitacao] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSolicitacao = async () => {
      try {
        setIsLoading(true);

        const data = await getSolicitacaoPorId(id);
        setSolicitacao(data);
      } catch (ex) {
        Sentry.captureException(ex);

        setError(ex.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (id != null) {
      fetchSolicitacao();
    }
  }, [id]);

  const downloadAllFilesIsDisabled = !solicitacao?.eventos?.some(
    evento => evento.documentos.length,
  );

  const handleDownloadAllFiles = async () => {
    const data = await downloadSolicitacaoAllFiles(id);
    fileDownload(data, `solicitacao_${id}_documentos.zip`);
  };

  const [novoEventoIsDisabled, setNovoEventoIsDisabled] = useState(false);

  useEffect(() => {
    setNovoEventoIsDisabled(solicitacao?.baixada_em != null);
  }, [solicitacao]);

  useEffect(() => {
    const requestReadNotificacao = async () => {
      const { search } = location;
      const query = new URLSearchParams(search);

      if (query.has('notificacao')) {
        const notificacaoId = query.get('notificacao');

        if (notificacaoId != null) {
          await readNotificacao(notificacaoId);
          dispatch(sidApi.util.invalidateTags(['Notificacao']));
        }
      }
    };

    requestReadNotificacao();
  }, [dispatch, location]);

  const [isReabrindoSolicitacao, setIsReabrindoSolicitacao] = useState(false);

  // TODO: Melhorar o tratamento da resposta ao reabrir uma solicitação
  const handleReabrirSolicitacao = async () => {
    if (solicitacao != null && setorAtual != null) {
      try {
        setIsReabrindoSolicitacao(true);

        await reabrirSolicitacao({
          solicitacaoId: solicitacao.id,
          setorCriacao: setorAtual,
        });

        const data = await getSolicitacaoPorId(solicitacao.id);
        setSolicitacao(data);

        toast({
          title: 'Reabrir solicitação',
          description: 'Solicitação reaberta com sucesso',
          status: 'success',
          duration: 1500,
          isClosable: true,
        });
      } catch (ex) {
        Sentry.captureException(ex);

        toast({
          title: 'Reabrir solicitação',
          description: 'Erro ao reabrir solicitação',
          status: 'error',
          duration: 1500,
          isClosable: true,
        });
      } finally {
        setIsReabrindoSolicitacao(false);
      }
    }
  };

  if (isLoading) {
    return <SolicitacaoLoading />;
  }

  if (error) {
    return <SolicitacaoError />;
  }

  let defensor;

  if (solicitacao.eventos.length) {
    const { username } = solicitacao.eventos[0].cadastrado_por;
    const setor = solicitacao.eventos[0].setor_criacao.nome;

    defensor = {
      nome: solicitacao.eventos[0].cadastrado_por.nome,
      username,
      email: solicitacao.eventos[0].cadastrado_por.email,
      setor,
      toString() {
        return `${username} (${setor})`;
      },
    };
  }

  return (
    <Container maxW="container.xl" p="2">
      <Flex
        direction={['column', null, 'row']}
        alignItems={novoEventoIsDisabled ? 'flex-start' : 'center'}
        mb="3"
        py="2"
      >
        <Heading size="lg" mb={['2', '2', '0']}>
          Solicitação nº {solicitacao.numero}
        </Heading>

        <HStack
          alignItems={novoEventoIsDisabled ? 'flex-start' : 'center'}
          ml={{ md: 'auto' }}
        >
          <Button
            as={RouterLink}
            to="/acompanhamento"
            leftIcon={<Icon as={FaArrowAltCircleLeft} />}
            colorScheme="blackAlpha"
          >
            Voltar
          </Button>

          <Button
            leftIcon={<Icon as={FaDownload} />}
            colorScheme="blue"
            onClick={handleDownloadAllFiles}
            isDisabled={downloadAllFilesIsDisabled}
          >
            Baixar arquivos
          </Button>

          <Box>
            {solicitacao.baixada_em != null ? (
              <Button
                key="-1"
                leftIcon={<Icon as={FaBookOpen} />}
                colorScheme="red"
                onClick={handleReabrirSolicitacao}
                isLoading={isReabrindoSolicitacao}
                loadingText="Reabrindo solicitação"
              >
                Reabrir
              </Button>
            ) : (
              <Button
                key="-2"
                as={RouterLink}
                to={`/solicitacoes/${solicitacao.id}/novo-evento`}
                leftIcon={<Icon as={FaCalendarPlus} />}
                colorScheme="green"
              >
                Novo evento
              </Button>
            )}
          </Box>
        </HStack>
      </Flex>

      <Grid gap="3" templateColumns={{ lg: '20rem 1fr' }}>
        <VStack spacing="2">
          <Box
            w="full"
            px="10"
            py="6"
            textAlign="center"
            fontWeight="bold"
            bgColor={SolicitacaoSituacaoColors[solicitacao.situacao].bgColor}
            textColor={
              SolicitacaoSituacaoColors[solicitacao.situacao].textColor
            }
          >
            {
              situacoesSolicitacao.find(el => el.id === solicitacao.situacao)
                ?.nome
            }
          </Box>

          {solicitacao.eh_urgente && (
            <Flex
              w="full"
              px="10"
              py="6"
              bgColor="red.300"
              textColor="white"
              fontWeight="bold"
              alignItems="center"
              justify="center"
            >
              <Icon as={FaExclamationCircle} fontSize="2xl" mr="1" />
              <span>Urgente</span>
            </Flex>
          )}

          <Box w="full" h="full" bg="gray.300" p="4">
            <dl>
              <chakra.dt fontWeight="bold">Origem:</chakra.dt>
              <chakra.dd mb="3">
                {solicitacao.defensoria_origem?.nome}
              </chakra.dd>

              <chakra.dt fontWeight="bold">Destino:</chakra.dt>
              <chakra.dd mb="3">
                {solicitacao.defensoria_destino?.nome}
              </chakra.dd>

              <chakra.dt fontWeight="bold">Localidade:</chakra.dt>
              <chakra.dd mb="3">{solicitacao.localidade?.nome}</chakra.dd>

              <chakra.dt fontWeight="bold">Área:</chakra.dt>
              <chakra.dd mb="3">{solicitacao.area?.nome}</chakra.dd>

              <chakra.dt fontWeight="bold">Tipo:</chakra.dt>
              <chakra.dd mb="3">{solicitacao.tipo?.nome}</chakra.dd>

              <chakra.dt fontWeight="bold">Processo:</chakra.dt>
              <chakra.dd mb="3">
                {solicitacao.processo?.numero
                  ? solicitacao.processo?.numero
                  : 'Não informado'}
              </chakra.dd>

              <chakra.dt fontWeight="bold">Pessoa Assistida:</chakra.dt>
              <chakra.dd mb="3">
                {solicitacao.pessoa?.nome
                  ? solicitacao.pessoa?.nome
                  : 'Não informado'}
              </chakra.dd>

              <chakra.dt fontWeight="bold">Defensor(a):</chakra.dt>
              <chakra.dd mb="3">{defensor?.toString()}</chakra.dd>
            </dl>
          </Box>
        </VStack>

        <Box
          border="1px solid"
          borderColor="gray.300"
          p="5"
          flex="1"
          borderRadius="lg"
          sx={{ overflow: 'auto' }}
        >
          <Box>
            {solicitacao.eventos.map(evento => (
              <Box
                key={evento.id}
                position="relative"
                _before={{
                  content: `""`,
                  display: 'block',
                  position: 'absolute',
                  top: 3,
                  left: 0,
                  transform: 'translatex(-50%)',
                  boxSize: 3,
                  bgColor: 'gray.400',
                  rounded: 'full',
                }}
                borderLeft="1px solid"
                borderColor="gray.300"
                pb="1px"
              >
                <Box
                  px="3"
                  pt="3"
                  pb="10"
                  border="1px solid"
                  borderColor="gray.300"
                  rounded="md"
                  shadow="sm"
                  ml="7"
                  mb="5"
                  position="relative"
                  _before={{
                    content: `""`,
                    display: 'block',
                    position: 'absolute',
                    top: '0.55rem',
                    left: 0,
                    transform: 'translatex(-100%)',
                    border: '8px solid',
                    borderColor: 'transparent',
                    borderRightColor: 'gray.300',
                  }}
                >
                  <chakra.p fontWeight="bold">
                    <strong>Evento:</strong> {evento.numero}
                  </chakra.p>

                  <p>
                    <strong>Data:</strong>{' '}
                    <InlineDateTime date={evento.cadastrado_em}>
                      {({ dateFormatted, timeFormatted }) => (
                        <chakra.span>
                          {dateFormatted} às {timeFormatted}
                        </chakra.span>
                      )}
                    </InlineDateTime>
                  </p>

                  <p>
                    <strong>Tipo:</strong> {evento.tipo.nome}
                  </p>

                  <p>
                    <strong>Setor:</strong> {evento.setor_criacao.nome}
                  </p>

                  <Box mb="3">
                    <strong>Histórico:</strong>{' '}
                    {evento.historico === '' ? (
                      'Nenhum histórico'
                    ) : (
                      <Box
                        className="html-editor"
                        p="5"
                        border="1px solid"
                        borderColor="gray.200"
                        sx={{
                          '& ul li, & ol li': {
                            marginLeft: '2rem',
                          },
                        }}
                      >
                        <div
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: evento.historico }}
                        />
                      </Box>
                    )}
                  </Box>

                  <div>
                    <chakra.p fontWeight="bold">Documentos:</chakra.p>

                    {evento.documentos.map(documento => (
                      <Button
                        variant="link"
                        key={documento.id}
                        style={{
                          color: '#3182ce',
                          display: 'inline-block',
                          margin: '0 5px',
                        }}
                        rel="noreferrer"
                        leftIcon={<Icon as={FaPaperclip} />}
                        onClick={async event => {
                          try {
                            event.preventDefault();
                            const response = await axios.get(
                              documento.arquivo,
                              {
                                responseType: 'arraybuffer',
                              },
                            );
                            fileDownload(response.data, documento.nome);
                          } catch (ex) {
                            Sentry.captureException(ex);

                            toast({
                              title: 'Erro',
                              description: 'Erro ao tentar baixa arquivo',
                              status: 'error',
                              duration: 1500,
                              isClosable: true,
                            });
                          }
                        }}
                      >
                        <span>{documento.nome}</span>
                      </Button>
                    ))}
                  </div>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
    </Container>
  );
}
