import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getSituacoesEvento } from '../../services/api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchSituacoesEvento = createAsyncThunk(
  'eventos/fetchSituacoesEvento',
  async () => {
    return getSituacoesEvento();
  },
);

export const situacoesEventoSlice = createSlice({
  name: 'situacoesEvento',

  initialState,

  extraReducers: {
    [fetchSituacoesEvento.pending]: state => {
      state.status = 'loading';
    },

    [fetchSituacoesEvento.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },

    [fetchSituacoesEvento.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default situacoesEventoSlice.reducer;

export const selectAllSituacoesEvento = state => state.situacoesEvento.data;
