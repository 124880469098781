import {
  Box,
  Button,
  chakra,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';

import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { useAuth } from '../../context/ProviderAuth';
import { alterarSenha } from '../../services/api';

const MessageKeyMap = {
  old_password: 'Senha atual',
  password: 'Nova senha',
  password_confirmation: 'Confirmar senha',
};

export default function AlterarSenha() {
  const { user } = useAuth();

  const [senhaAtual, setSenhaAtual] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmacaoSenha, setConfirmacaoSenha] = useState('');

  const handleChangeSenhaAtual = ({ target: { value } }) =>
    setSenhaAtual(value);
  const handleChangeNovaSenha = ({ target: { value } }) => setNovaSenha(value);
  const handleConfirmacaoSenha = ({ target: { value } }) =>
    setConfirmacaoSenha(value);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleAtualizarSenha = async () => {
    try {
      setIsLoading(true);

      await alterarSenha(senhaAtual, novaSenha, confirmacaoSenha, user.id);
      setSuccess(true);
      setError(null);
    } catch (ex) {
      Sentry.captureException(ex);

      setSuccess(false);

      if (ex.response?.status >= 400 && ex.response?.status < 500) {
        setError(ex.response?.data || ex.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxW="container.xl" p="2">
      <Box
        maxW="96"
        mx="auto"
        mt="10"
        shadow="md"
        px="10"
        py="10"
        borderTop="1px solid"
        borderColor="gray.100"
      >
        <Heading as="h2" mb="8" textAlign="center">
          Alterar Senha
        </Heading>

        <FormControl mb="3">
          <FormLabel>Senha atual</FormLabel>
          <Input
            value={senhaAtual}
            onChange={handleChangeSenhaAtual}
            onKeyDown={event =>
              event.code === 'Enter' ? handleAtualizarSenha() : null
            }
            type="password"
          />
        </FormControl>

        <FormControl mb="3">
          <FormLabel>Nova senha</FormLabel>
          <Input
            value={novaSenha}
            onChange={handleChangeNovaSenha}
            onKeyDown={event =>
              event.code === 'Enter' ? handleAtualizarSenha() : null
            }
            type="password"
          />
        </FormControl>

        <FormControl mb="3">
          <FormLabel>Confirmar senha</FormLabel>
          <Input
            value={confirmacaoSenha}
            onChange={handleConfirmacaoSenha}
            onKeyDown={event =>
              event.code === 'Enter' ? handleAtualizarSenha() : null
            }
            type="password"
          />
        </FormControl>

        <Button
          onClick={handleAtualizarSenha}
          colorScheme="green"
          w="full"
          mb="3"
          isLoading={isLoading}
        >
          ATUALIZAR SENHA
        </Button>
      </Box>

      {success && (
        <Box
          py="3"
          px="4"
          borderRadius="md"
          maxW="container.sm"
          mx="auto"
          mt="6"
          textAlign="center"
        >
          <Text fontWeight="bold" fontSize="1.5rem" mb="3">
            Senha alterada com sucesso!
          </Text>
          <Link
            as={RouterLink}
            to="/acompanhamento"
            color="green.700"
            fontSize="1.05rem"
          >
            Voltar para a tela de acompanhamento
          </Link>
        </Box>
      )}

      {error && (
        <Box
          bgColor="red.400"
          color="white"
          py="3"
          px="4"
          borderRadius="md"
          maxW="container.sm"
          mx="auto"
          mt="6"
        >
          {typeof error === 'object' ? (
            <>
              <Text mb="3">
                Ocorreu algum erro ao tentar alterar a sua senha. Verifique o
                seguinte:
              </Text>

              <chakra.ul>
                {Object.entries(error).map(([key, messages]) => (
                  <chakra.li key={key} listStyleType="none">
                    - {MessageKeyMap[key]}: {messages.join('. ')}
                  </chakra.li>
                ))}
              </chakra.ul>
            </>
          ) : (
            <Text mb="3">
              Ocorreu algum erro ao tentar alterar a sua senha. Já notificamos
              os nossos desenvolvedores sobre o ocorrido. Tente novamente mais
              tarde e se o problema persistir entre em contato com o
              administrador do sistema.
            </Text>
          )}
        </Box>
      )}
    </Container>
  );
}
