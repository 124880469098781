import { useEffect, useState } from 'react';

import { getTiposArquivo } from '../services/api';

export default function useFetchTiposArquivos(defensoriaId) {
  const [tiposArquivo, setTiposArquivo] = useState([]);

  useEffect(() => {
    const fetchTiposArquivo = async () => {
      if (defensoriaId !== null && defensoriaId !== '') {
        const data = await getTiposArquivo(defensoriaId);
        setTiposArquivo(data);
      }
    };

    fetchTiposArquivo();
  }, [defensoriaId]);

  return tiposArquivo;
}
