import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getLocalidades } from '../../services/api';

const initialState = {
  data: [],
  status: 'idle',
  error: null,
};

export const fetchLocalidadesPorDefensoria = createAsyncThunk(
  'localidades/fetchLocalidadesPorDefensoria',
  async defensoriaId => {
    return getLocalidades(defensoriaId);
  },
);

export const localidadesSlice = createSlice({
  name: 'localidades',

  initialState,

  extraReducers: {
    [fetchLocalidadesPorDefensoria.pending]: state => {
      state.status = 'loading';
    },

    [fetchLocalidadesPorDefensoria.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },

    [fetchLocalidadesPorDefensoria.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export default localidadesSlice.reducer;

export const selectLocalidadesPorDefensoria = state => state.localidades.data;
