import { Box, HStack, Skeleton } from '@chakra-ui/react';

export default function ResumoAnoLoading() {
  return (
    <Box>
      <Box px="2">
        <Skeleton h="6" w="72" mb="2" />
      </Box>

      <HStack h="100" w="full" p="2">
        <Skeleton h="90" flex="1" />
        <Skeleton h="90" flex="1" />
        <Skeleton h="90" flex="1" />
        <Skeleton h="90" flex="1" />
      </HStack>
    </Box>
  );
}
